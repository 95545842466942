.bg-container {
  position: relative;
  min-height: 80vh;
  height: 270vh;
}
.bg-refund-container {
  position: relative;
  min-height: 80vh;
  height: 400vh;
}

.bg-contact-container {
  position: relative;
  min-height: 50vh;
  height: 170vh;
}
.registerBg-container {
  position: relative;
  min-height: 100vh;
  height: 200vh;
  background-image: url(../assets/images/deleteUserbg.jpg);
}

.pricing-container {
  position: relative;
  min-height: 100vh;
  height: 330vh;
  background-image: url(../assets/images/deleteUserbg.jpg);
}

.bg-contact-image {
  position: relative;
  height: 90%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blurred-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../assets/images/check4.jpg);
  filter: blur(8px);
  -webkit-filter: blur(10px);
  z-index: -1;
}


.bg-image {
  background-image: url(../assets/images/check4.jpg);
  filter: blur(8px);
  -webkit-filter: blur(10px);
  height: 90%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-container {
  position: relative;
  min-height: 80vh;
  height: 270vh;
}


.bg-image-pp {
  background-image: url(../assets/images/check4.jpg);
  filter: blur(8px);
  -webkit-filter: blur(10px);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-container-pp {
  position: relative;
  min-height: 80vh;
  height: 2750vh;
}

.bg-image-tnc {
  background-image: url(../assets/images/check4.jpg);
  filter: blur(8px);
  -webkit-filter: blur(10px);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-container-tnc {
  position: relative;
  min-height: 80vh;
  height: 1425vh;
}

.bg-image-tnc1 {
  background-image: url(../assets/images/Hotel.jpg);
  filter: blur(8px);
  -webkit-filter: blur(10px);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-image-pp1 {
  background-image: url(../assets/images/Hotel.jpg);
  filter: blur(8px);
  -webkit-filter: blur(10px);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.bg-image-rp {
  background-image: url(../assets/images/check4.jpg);
  filter: blur(8px);
  -webkit-filter: blur(10px);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-container-rp {
  position: relative;
  min-height: 80vh;
  height: 270vh;
}


.wrapper {
  padding: 2rem; 
  font-size : 20px;
  color : #ffffff;
  padding: 60px;
  text-align: 'justify';

}



.wrapper ul {
  list-style-type: disc; 
  padding-left: 30px; 
}

.wrapper li {
  margin-bottom: 1rem; 
}
.wrapper p {
  margin-bottom: 2rem; 
}

.wrapper h1 {
  margin-bottom: 1rem; 
}


@media screen and (min-width: 320px) {
  .bg-container {
    height: 500vh;
  }
}
@media screen and (min-width: 375px) {
  .bg-container {
    height: 630vh;
  }
}
@media screen and (min-width: 425px) {
  .bg-container {
    height: 540vh;
  }
}
@media screen and (min-width: 768px) {
  .bg-container {
    height: 440vh;
  }
}
@media screen and (min-width: 1024px) {
  .bg-container {
    height: 350vh;
  }
}
@media screen and (min-width: 1280px) {
  .bg-container {
    height: 300vh;
  }
}
@media screen and (min-width: 1500px) {
  .bg-container {
    height: 320vh;
  }
}
