@import url('assets/css/style.css');
@import url('assets/css/main.css');

.app {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  overflow-y: auto;
}
