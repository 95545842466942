@charset "utf-8";

@import url('bootstrap.min.css'); 
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');


body {
    margin: 0;
    padding: 10px;
    font-family: 'Mulish', sans-serif !important;
    font-size: 13px;
    color: #3a3a3a;
    font-weight: 400;
    background: #f6f7ff;
}

* {
    margin: 0px;
    padding: 0px;
}

.clearfix {
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix:after {
    clear: both;
}

.clr,
.clear {
    clear: both;
}

header,
footer,
aside,
article,
section, 
nav,
figure {
    position: relative; 
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul,
li,
ol {
    /* list-style-type: none; */
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: none !important;
    outline: none;
}

img {
    border: 0;
    outline: none;
    max-width: 100%; 
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
    max-width: 100%;
}

table {  width: 100%; }
p { margin: 0; padding: 0; }
.fltL { float: left; }
.fltR { float: right; }
.padding_none { padding: 0 !important;}
.border_none{border:none !important}
.navbar-toggle {  background-color: #fff !important; }
.icon-bar { background-color: #000 !important; }
.p_right0{ padding-right:0px !important }
.p_left0{ padding-left:0px !important } 
.m_bottom0{margin-bottom: 0 !important;}

input[type="date"] {text-transform: uppercase !important;}

.Green{ color: #37a713; }
.Red{ color: #e21818; }
.Blue{ color: #139bbf; }
.Yellow{ color: #feca05; }
.Pink{color: #fd546d;}
.ButtonFade{border:none;padding: 11px 30px;text-align: center;transition:0.5s all ease-in-out;font-weight: 600;position:relative;font-size:15px;color:#fff;text-transform:capitalize;border-radius: 5px;outline: 0 !important;box-shadow: none;background: #a8a6a0;display: inline-block;}


.Button{border:none;padding: 11px 30px;text-align: center;transition:0.5s all ease-in-out;font-weight: 600;position:relative;font-size:15px;color:#fff;text-transform:capitalize;border-radius: 5px;outline: 0 !important;box-shadow: none;background: #dfa92d;display: inline-block;}
.Button:hover{background-position: 0px 80px;color: #fff;}
.Button.Cancel{background: #d9d9d9;color: #000;margin: 0 0 0 10px;padding: 10px 20px;}
.Button.RoomChangeRequest{background: #dfa92d;color: #FFFFFF;margin: 0 0 0 550px;padding: 10px 20px;}

.Button.RoomChange{background: #dfa92d;color: #FFFFFF; padding: 10px 10px; width: 120px; text-align: center; margin-right: 10px; margin-left: 20px;}
.Button.Reply {background: #dfa92d;color: #FFFFFF; padding: 10px 10px; width: 100px; text-align: center; margin-right: 10px; margin-left: 20px; }
.ReplyReview {display:flex; flex-direction: row;justify-content: flex-end;}
.ReplyHotelDetail {font-size: 14px;}
.reply-hotel-info{display:flex; flex-direction: row; flex:1}
.reply-info{display:flex; flex-direction: column; flex:1;justify-content: flex-start;}
.reply-text{ padding-left: 70px;}
.Button.addd{margin: 0px 0px 0px 10px;}
.ReplyReviewBox{background: #ffffff;border-radius: 10px;border: 1px solid #dddddd;padding: 20px;}
.switch{position:relative;display:inline-block;cursor:pointer;margin:0;padding:2px 0 3px 50px;font-weight:400;font-size:12px;color:#646467;float: right;}
.switch input{opacity:0;width:0;height:0}
.switch .slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#fff;transition:0.4s;height: 21px;border-radius:50px;width:40px;border: 1px solid #165885;min-width: auto;}
.switch .slider:before{position:absolute;content:"";height:13px;width:13px;left:3px;bottom:0;top:0;background-image: linear-gradient(45deg,#165885,#165885);transition:0.4s;border-radius:50%;margin:auto;}
.switch input:checked+.slider{background-image: linear-gradient(45deg,#165885,#354a58);}
.switch input:checked+.slider:before{-webkit-transform:translateX(19px);transform:translateX(19px);background:#fff}


.CheckBox input{position:absolute;opacity:0;cursor:pointer;height:0;width:0}
.CheckBox .checkmark{position:absolute;top:0;left:0;height: 20px;width: 24px;background-color:transparent;border: 1px solid #0c2230;border-radius:2px;min-width: auto;}
.CheckBox:hover input~.checkmark{background: #0c2230;}
.CheckBox input:checked~.checkmark{background: #0c2230;}
.CheckBox .checkmark:after{content:"";position:absolute;display:none;left:8px;top:2px;width:5px;height:11px;border:solid white;border-width:0 2px 2px 0;transform:rotate(45deg)}
.CheckBox input:checked~.checkmark:after{display:block}

.Radio{display:block;position:relative;padding-left:35px;cursor:pointer;font-size:22px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;margin: 0 !important;}
.Radio input{position:absolute;opacity:0;cursor:pointer}
.Radio .checkmark{position:absolute;top:0;left:0;height:22px;width:22px;background-color:#fff;border-radius:50%;border: 1px solid #0c2230;}
.Radio:hover input~.checkmark{background-color: #0c2230;}   
.Radio input:checked~.checkmark{border-color: #0c2230;background-color: #0c2230;}
.Radio .checkmark:after{content:"";position:absolute;display:none}
.Radio input:checked~.checkmark:after{display:block}
.Radio .checkmark:after{width: 10px;height: 10px;border-radius:50%;background:#fff;top:0;left:0;bottom:0;right:0;margin:auto}

.LoginArea{background-image: linear-gradient(45deg,#0c2230,#196497);min-height:100vh;display:flex;align-items:center;justify-content:center;padding: 40px 0;    }
.LoginArea .LoginBox{background-color:#fff;padding: 50px 50px 50px 50px;box-shadow: 0 0 10px #858585;border-radius: 10px;width: 480px;position: relative;}
.LoginArea .LoginBox .Language{position: absolute;top: 15px;right: 15px;display: flex;align-items: center;}
.LoginArea .LoginBox .Language span{font-size: 20px;color: #000;margin: 0 10px 0 0;line-height: 22px;}
.LoginArea .LoginBox .Language select{border: none;font-size: 14px;padding: 7px 0;cursor: pointer;font-weight: 600;outline: 0;}
.LoginArea .LoginBox figure{text-align:center;margin: auto;width: 85px;padding: 0px 0px 20px 0px;}
.LoginArea .LoginBox figure img{}
.LoginArea .LoginBox form{margin:auto}
.LoginArea .LoginBox form h3{font-size:28px;margin: 0 0 10px 0;font-weight:700;color:#000;text-align: center;}
.LoginArea .LoginBox form p{font-size:14px;margin:0 0 20px 0;font-weight:600;text-align:center}
.LoginArea .LoginBox form .form-group{margin:0 0 20px 0; position: relative;}
.LoginArea .LoginBox form .form-group label{display:block;color:#000;font-size:14px;font-weight:700}
.LoginArea .LoginBox form .form-group label a{float:right}
.LoginArea .LoginBox form .form-group .form-control{box-shadow:none;border: 2px solid #ddd;padding:10px 15px;height:auto;border-radius:5px;font-size:15px;color:#000;font-weight: 500;}
.LoginArea .LoginBox form .form-group .form-control.Authentication{padding: 10px 40px;}
.LoginArea .LoginBox form .form-group .form-control:focus{border-color: #144c72;}
.LoginArea .LoginBox form .form-group .Icon {position: absolute;top: 42px;right: 15px;font-size: 15px;}
.LoginArea .LoginBox form .form-group .Verify {
    position: absolute;
    top: 44px;
    right: 14px;
    font-size: 13px;
    font-weight: 700;
}
.LoginArea .LoginBox form .form-group .lock {position: absolute;top: 10px;left: 14px;}
.LoginArea .LoginBox form .form-group .Checkboxs{display:flex;justify-content:space-between;align-items:center}
.LoginArea .LoginBox form .form-group .Checkboxs a{color:#615e5e;text-transform:capitalize;font-weight:600;font-size:15px}
.LoginArea .LoginBox form .form-group .OTPBox{display:flex;justify-content:space-between;margin: 0 0 10px 0;}
.LoginArea .LoginBox form .form-group .OTPBox .form-control{padding:0;text-align:center;width:60px;height:55px;font-size:22px;font-weight:600;color:#000;line-height:30px;    border-color: #959191; }
.LoginArea .LoginBox form .Button{display:block}
.LoginArea .LoginBox h6{text-align:center;margin-top:15px;font-size:15px;font-weight:600;color:#000}
.LoginArea .LoginBox h6 a{color: #0c2230;font-weight:700;text-decoration: underline;}

.LoginArea .LoginBox form .form-group .form-control textarea{
    padding: 10px;
}
.SuccessArea{background-image:linear-gradient(45deg,#0c2230,#196497);min-height:100vh;display:flex;align-items:center;justify-content:center;padding:0 0}
.SuccessArea .SuccessBox{background-color:#fff;min-width:525px;text-align:center;padding:60px 50px;border-radius:20px}
.SuccessArea .SuccessBox span{width:100px;display:block;margin:0 auto 10px}
.SuccessArea .SuccessBox h3{font-size:36px;font-weight:800;margin:0 0 10px 0;color:#000}
.SuccessArea .SuccessBox p{font-size:15px;font-weight:600;margin:0 0 20px 0}
.SuccessArea .SuccessBox a{display:inline-block;padding:12px 30px;background:linear-gradient(45deg,#dfa92d,#dfa92d);border-radius:5px;color:#fff;font-size:16px;font-weight:600}
 
.RegisterArea .LoginBox{ width:800px }

.CommonForm h4 {font-size: 16px;padding: 15px 20px;background-color: #f1efef;font-weight: 700;color: #000;margin: 0 0 30px 0px;}
.CommonForm h4 a{color:#000;float: right;font-size: 14px;font-weight: 700;}
.CommonForm .form-group{margin: 0 0 35px 0;position: relative;}
.CommonForm .form-group .form-group{margin:0 0 0 0}
.CommonForm .form-group label{display:block;color:#000;font-weight:700;font-size:14px;text-transform:capitalize;margin: 0;}
.CommonForm .form-group .AddMore{color:#000; font-size: 14px;font-weight: 700;}
.CommonForm .form-group .Avaialable{}
.CommonForm .form-group .Avaialable li{display: inline-block;width: 32%;position: relative;}
.CommonForm .form-group .Avaialable li .Radio{position:relative;padding: 0 0 0 30px;top: 20px;}
.CommonForm .form-group .UploadBox .Upload{border:2px dashed #b9b9b9;padding: 37px 0;border-radius: 8px;position:relative;display: inline-block;width: 160px;height: 125px;text-align: center;background-color: #fff;}
.CommonForm .form-group .UploadBox .Upload i{font-size:24px}
.CommonForm .form-group .UploadBox .Upload span{font-size: 14px;font-weight:600;color:#000;text-transform:capitalize;display: block;}
.CommonForm .form-group .UploadBox .Upload input{position:absolute;top:0;left:0;width:100%;height:100%;cursor:pointer;opacity:0}
.CommonForm .form-group .UploadBox figure{margin:0 auto 0;width:170px}
.CommonForm .form-group .Verify{font-weight: 800;position: absolute;top: 12px;right: 15px;cursor: pointer;}
.CommonForm .ServiceAdd{background-color: #ddd;padding: 20px 20px 2px 20px;border-radius: 5px;margin: 0 0 20px 0;}
.CommonForm .ServiceAdd .form-group{background-color: #fff;padding: 20px;border-radius: 5px;}
.CommonForm .ServiceAdd .form-group aside{display: flex;}
.CommonForm .ServiceAdd .form-group aside .form-control{margin: 0 10px 0 0;width: 250px;}
.CommonForm .ServiceAdd .form-group aside button.Delete{border: none;background-color: #ffe5e5;padding: 0 15px;border-radius: 5px;color: #ed0000;font-size: 22px;}
.CommonForm .form-group .ck.ck-editor { margin: 0 0 20px 0;}
.CommonForm .form-group .ck.ck-label.ck-voice-label {display: none;}
.CommonForm .form-group .ck.ck-content.ck-editor__editable { padding: 10px;min-height: 275px;}
.CommonForm .form-group .ExtraFeed li{margin: 0 0 13px 0;}
.CommonForm .form-group .ExtraFeed li:last-child{ margin:0 }
.CommonForm .form-group .ExtraFeed li h5{margin: 0;display: inline-block;min-width: 220px;font-size: 15px;font-weight: 700;color: #000;}
.CommonForm .form-group .ExtraFeed li label{display: inline-block;position: relative;padding: 0 0 0 35px;top: 0;left: 0;}

.CommonForm .form-group .TableList table tr td input{display:inline-block;padding: 10px;}
.LoginArea .LoginBox form p.step-pera {
    text-align: left;
    margin: -10px 0px 18px;
}
.CommonForm .form-group .form-control {
    height: auto;
    padding: 10px 10px 10px 15px;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    box-shadow: none;
    font-weight: 600;
    font-size: 14px;
}
.CommonForm .form-group p a {
    color: #000;
    font-weight: 700;
    font-size: 15px;
}

.CommonForm .form-group .react-tel-input .form-control {    
    width: 85%;
    padding: 10px 10px 10px 55px;


}
.verification{
    margin: 0px 0px 20px;
    box-shadow: none;
    border: 2px solid #ddd;
    padding: 10px 15px;
    height: auto;
    border-radius: 5px;
    font-size: 15px;
    color: #000;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
}
.LoginArea .LoginBox form .verification h3{
    font-size: 15px;
    text-align: left;
    margin: 0px 0px 0px 12px;
}
.verification span{
}
.verification .Actions{
    position: absolute;
    top: 40px;
    right: 20px;
}

.LoginArea .LoginBox form h3.description{
    color: #000;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
}
/* .amenities{
    margin: 40px 0px;
} */
.amenities h5{
    color: #000;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;

}
.amenities .form-group label {
    position: relative;
    top: 0px;
    padding: 0px 0px 0px 30px;
}
.amenities .CheckBox .checkmark{
    width: 18px;
    height: 18px;
}
.amenities .CheckBox .checkmark:after{
    left: 6px;
    top: 3px;
    width: 5px;
    height: 8px;
}
.amenities ul{margin-bottom: -15px;}

.amenities ul li{
    display: inline-block;
    width: 25%;
    margin: 0px 0px 15px 0px;
}


.amenities ul li label{  font-size: 14px !important; }

textarea.form-control {
    width: 100%;
    margin: auto;
    box-shadow: none;
    border: 2px solid #ddd;
    padding: 10px 15px;
    height: auto;
    border-radius: 5px;
    font-size: 15px;
    color: #000;
    font-weight: 500;
}


.Header{position:fixed;left:255px;top: 0;z-index:99;width:calc(100% - 275px);padding: 10px 0 0 0;}
.Header .HeaderBox{background-color: #165885;display:flex;justify-content:space-between;align-items:center;padding:0 20px;border-radius:10px;box-shadow:0 0 5px #ddd;width: 100%;}

.Header .HeaderLeft{display:flex;align-items:center}
 .Header .HeaderLeft .HotelLocation aside h3{ 
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    text-align: left;
}
.Header .HeaderLeft .HotelLocation aside p{
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    text-align: left;

} 
.Header .HeaderLeft .HotelLocation aside span{
    color: #000;


}


.Header .HeaderLeft .Toggle{width:40px;height:40px;background:#fff;border-radius:50px;display:flex;align-items:center;justify-content:center;margin:0 15px 0 0;cursor:pointer;border:1px solid #dbe2eb}
.Header .HeaderLeft .Toggle svg{margin:0!important}
.Header .HeaderLeft .SearchBox{position:relative}
.Header .HeaderLeft .SearchBox .form-control{height:auto;padding:10px 30px 10px 15px;background-color:#fff;font-size:14px;box-shadow:none;min-width:500px;border:1px solid #dbe2eb}
.Header .HeaderLeft .SearchBox span{position:absolute;top:10px;right:15px;color:#afadad;font-size:15px;font-weight:500}
.Header .Navigation{transition:all 0.2s ease;padding: 0;/* height:60px; *//* background-image: linear-gradient(45deg,#fd701e,#fd894e); */display: flex;align-items: center;justify-content: end;}
.Header .Navigation .Bell{display: inline-block;position: relative;margin: 0 0 0 20px;}
.Header .Navigation .Bell .Count{background-color:#fff;position: absolute;color: #000;font-size: 11px;width: 20px;height: 20px;border-radius: 50px;text-align: center;right: -8px;top: -4px;font-weight: 700;border: 1px solid #dbe2eb;line-height: 19px;}
.Header .Navigation .Bell a{color: #666464;font-size: 15px;width: 35px;height: 35px;background: #fff;border-radius: 50px;display: flex;align-items: center;justify-content: center;cursor: pointer;border: 1px solid #dbe2eb;}
.Header .Navigation .Avater{margin: 0px 0 0px 30px;position:relative;display:inline-block;}
.Header .Navigation .Avater a{display:flex;align-items:center;font-size:14px;color: #fff;padding: 15px 0;}
.Header .Navigation .Avater a figure{width:35px;border-radius:50%;margin:0 15px 0 0;overflow:hidden;height: 35px;border: 2px solid #fff;box-shadow: 0px 0px 5px #ddd;}
.Header .Navigation .Avater ul{position: absolute;background: #fff;box-shadow: 0 3px 11px 0 rgb(0 0 0 / 10%);-webkit-transform: perspective(600px) rotateX(-90deg);transform: perspective(600px) rotateX(-90deg);-webkit-transform-origin: 0 0 0;transform-origin: 0 0 0;right: 0;opacity: 0;visibility: hidden;-webkit-transition: 0.5s;transition: 0.5s;z-index: 99;top: 100%;text-align: left;min-width: 200px;display: block;width: auto;}
.Header .Navigation .Avater ul li{margin:0;border-bottom:1px solid #d2d3d4}
.Header .Navigation .Avater ul li:first-child{background-image: linear-gradient(45deg, #e4e3f6, #f8eee4);position:relative;padding: 15px 15px 15px 60px;text-align:left;border-bottom:1px solid #fff;}
.Header .Navigation .Avater ul li figure{width:35px;height:35px;border-radius:50%;position:absolute;top:5px;left:15px;border:2px solid #fff;overflow:hidden;margin: 0;}
.Header .Navigation .Avater ul li h4{color: #000;font-size:17px;line-height:18px}
.Header .Navigation .Avater ul li h4 span{font-size:11px;font-weight:400;display: block;}
.Header .Navigation .Avater ul li a{color:#4e4e4e;padding:10px 20px;font-weight:400;transition:0.5s all ease-in-out}
.Header .Navigation .Avater ul li a span{margin:0 10px 0 0}
.Header .Navigation .Avater ul li a:hover{background-image: linear-gradient(45deg,#165885,#5bb0e9);color:#fff;}
.Header .Navigation .Avater:hover ul{opacity: 1;visibility: visible; transform: perspective(600px) rotateX(0deg);}
.Header .Navigation .FinacialBox{text-align: center;}
.Header .Navigation .FinacialBox h6{font-weight: 700;color: #000;font-size: 16px;}
.Header .Navigation .FinacialBox p{font-size: 12px;font-weight: 600;color: #7c7c7c;letter-spacing: 0.5px;}

.SidenavBar{height: calc(100vh - 20px);position:fixed;width: 240px;top: 10px;padding:0;/* box-shadow:0 0 15px rgba(0,0,0,.1); */background-color: #fff;border-radius: 10px;}
.SidenavBar .SidenavHead{border-bottom: 2px solid #f6f7ff;padding: 11px 10px;text-align: center;}
.SidenavBar .SidenavHead img{width: 85px;}
.SidenavBar .SidenavBody{height: calc(100% - 85px);overflow: auto;}
.SidenavBar .SidenavBody::-webkit-scrollbar-track{background: #fff;border-radius:0}
.SidenavBar .SidenavBody::-webkit-scrollbar-thumb{border-radius: 40px;transition:background-color 0.2s linear,width 0.2s ease-in-out;-webkit-transition:background-color 0.2s linear,width 0.2s ease-in-out;background: #e4e4e9;}
.SidenavBar .SidenavBody::-webkit-scrollbar{width: 3px;border-radius:0}
.SidenavBar .SidenavBody ul{margin:0;padding: 10px;}
.SidenavBar .SidenavBody ul li{margin: 0 0 10px 0;}
.SidenavBar .SidenavBody ul li:last-child{ margin-bottom:0 }
.SidenavBar .SidenavBody ul li a{display:block;cursor:pointer; transition: 0.5s all ease-in-out; padding: 12px 20px 12px 50px;transition:0.5s all ease-in-out;color:#484848;font-family:"Roboto";font-size: 13px;text-transform:capitalize;position:relative;border-radius: 50px;}
.SidenavBar .SidenavBody ul li a span{font-size: 14px;display:inline-block;position:absolute;top: 6px;left: 7px;width: 32px;height: 32px;background-color: #fff;text-align: center;border-radius: 50px;line-height: 33px;box-shadow: 0px 0px 4px #ddd;}
.SidenavBar .SidenavBody ul li a.dropdown-toggle:after{content: '\f105';font-family: FontAwesome;position: absolute;top: 17px;right: 15px;font-size: 13px;border: none;font-weight: 700;line-height: 10px;}
.SidenavBar .SidenavBody ul li a:hover{background-color: #ebeef1;color: #000;}
.SidenavBar .SidenavBody ul li.active a{background-color: #ebeef1;color: #000;font-weight: 500;}
.SidenavBar .SidenavBody ul li ol{position: relative !important;float: none;transform: inherit !important;border: none;margin: 0;padding: 0;}
.SidenavBar .SidenavBody ul li ol li{margin: 15px 0 15px 0;padding: 0;}
.SidenavBar .SidenavBody ul li ol li:last-child{ margin-bottom:0 }
.SidenavBar .SidenavBody ul li ol li a{padding: 0 0 0 50px;background-color: transparent !important;color: #5e5e5e !important;font-weight: 400;}


.SidenavBar .SidenavBody ul li ol li a span {
    width: auto;
    height: auto;
    line-height: initial;
    top: auto;
    left: 28px;
    box-shadow: none;
}


.SidenavBar .SidenavBody ul li ol li.active a{color: #f98c1c !important;}
.SidenavBar .SidenavBody ul li.active ol{ display: block; }
.SidenavBar .SidenavFooter{background-image:linear-gradient(45deg,#e4e3f6,#f8eee4);padding:10px 15px;position:absolute;bottom:10px;width:calc(100% - 20px);left:10px;border-radius:10px;text-align:center;overflow:hidden}
.SidenavBar .SidenavFooter:before{content:'';width:90px;height:90px;background-color:rgb(255 255 255 / 50%);position:absolute;top:-20px;left:-30px;border-radius:50%}
.SidenavBar .SidenavFooter:after{content:'';width:90px;height:90px;background-color:rgb(255 255 255 / 50%);position:absolute;bottom:-20px;right:-30px;border-radius:50%}
.SidenavBar .SidenavFooter h5{color:#000;font-weight:800;margin:0 0 10px 0;font-size:18px}
.SidenavBar .SidenavFooter p{font-size:12px;line-height:20px;font-weight:600;color:#626262;margin:0 0 10px 0}
.SidenavBar .SidenavFooter a{display:block;background-color:#fff;padding:11px 0;border-radius:5px;color:#000;font-weight:700;box-shadow:0 1px 12px #999;font-size:13px;position:relative;z-index:9}

.WelcomeBox{background-color:#fff;box-shadow:0 0 5px #ddd;padding:15px;border-radius:10px;display:flex;align-items:center;margin:0 0 20px 0}
.WelcomeBox figure{width:80px;height:80px;border-radius:50%;overflow:hidden;margin:0}
.WelcomeBox figure img{width:100%;height:100%}
.WelcomeBox figcaption{padding:0 0 0 15px}
.WelcomeBox figcaption h3{color:#000;font-weight:700;font-size:24px;margin:0 0 5px 0}
.WelcomeBox figcaption p{color:#6e6e6e;font-size:14px;font-weight:600}

.WelcomeBox{height:120px;}

.WelcomeBox h4 {
    font-size: 14px;
    margin: 0 0 10px 0;
}

.WelcomeBox h4 strong {
    width: 140px;
    display: inline-block;
    margin: 0 10px 0 0;
    color: #000;
    font-weight: 700;
    position: relative;
}


.WelcomeBox h4 strong::after{
    content: ':';
    position: absolute;
    right: 0;
    top: 0;
}

.WelcomeBox h4 span{font-weight: 800;}

.DashboardArea{padding:0 0}
.DashboardArea .DashboardBox a{display:flex;align-items:center;justify-content:space-between;padding:25px 25px}
.DashboardArea .DashboardBox{border-radius: 10px;margin: 0px 0 25px 0;overflow:hidden;position:relative;height: 180px;}
.DashboardArea .DashboardBox:before{content:'';position:absolute;width:125px;height:125px;background-color:rgb(255 255 255 / 30%);top:-25px;right:-25px;border-radius:50%}
.DashboardArea .DashboardBox:after{content:'';position:absolute;width:125px;height:125px;background-color:rgb(255 255 255 / 30%);bottom:-25px;right:-25px;border-radius:50%}
.DashboardArea .DashboardBox aside{width: 75px;height: 75px;/* border:7px solid #fff; *//* border-top-color:#fd8444; */border-radius:50%;display:flex;align-items:center;justify-content:center;background-color: #fff;}
.DashboardArea .DashboardBox aside span{font-size: 30px; font-weight:800}
/* .DashboardArea .DashboardBox article{text-align:right}
.DashboardArea .DashboardBox article p{font-size:15px;color:#fff;font-weight:600;margin:0 0 6px 0}
.DashboardArea .DashboardBox article h5{color:#fff;font-size:32px;font-weight:800} */

.DashboardArea .DashboardBox article{text-align:bottom;color:white}
.DashboardArea .DashboardBox article h5{color:#fff;font-size:18.8px;font-weight:680;padding-bottom: 7px;}
.DashboardArea .DashboardBox article p{color:#fff;font-size:15.7px;font-weight:700;padding-bottom: 7px;}
.DashboardArea .DashboardBox .card-icon {padding-top: 10px;}
.DashboardArea .DashboardBox .card-data {padding-left: 17px;padding-bottom: 15px;font-size: 1rem;}
/* .DashboardArea .DashboardBox .card-datas {padding-left: 5px;padding-bottom: 15px;} */

.DashboardBox {
    padding: 15px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .dashboard-content {
    display: flex;
    align-items: flex-start;
  }

  .dashboard-icon {
    margin-right: 15px;
  }

  .dashboard-icon img {
    width: 48px;
    height: 48px;
  }

  .dashboard-info {
    flex-grow: 1;
  }

  .dashboard-info h5 {
    margin-bottom: 5px;
    font-size: 1rem;
  }

  .dashboard-stats {
    font-size: 0.9rem;
    display: grid;
    grid-template-columns: 1.3fr 1.3fr;
    gap:8px;
  }

  

  .stat-row {
    display: contents;
  }

   .feedbackReview{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
   }

  .stat-row span {
   text-align: left;
   padding-right: 20px;gap: 8px;
  }

  .stat-row span:nth-child(even) {
    text-align: right;
  }

  .stat-row span:nth-child(even) {
    text-align: left;
  }

/* //dashboard stars */

.star-rating {
    display: inline-flex;
    align-items: center;
  }

  .star-rating svg {
    font-size: 12px; 
    margin: 0 2px;
  }

  .star-rating svg.golden {
    color: #ffd700; 
  }

  .star-rating svg.white {
    color: #ffffff;
  }
.DashboardBox.Dashboard1{background-color:#ac70ba} 
.DashboardBox.Dashboard1 aside span{color:#ac70ba}
.DashboardBox.Dashboard2{background-color:#00caa9} 
.DashboardBox.Dashboard2 aside span{color: #00caa9;}
.DashboardBox.Dashboard3{background-color:#016bb5} 
.DashboardBox.Dashboard3 aside span{color: #016bb5;}
.DashboardBox.Dashboard4{background-color:#03a4cf} 
.DashboardBox.Dashboard4 aside span{color: #03a4cf;}
.DashboardBox.Dashboard5{background-color:#e17d43} 
.DashboardBox.Dashboard5 aside span{color: #e17d43;}
.DashboardBox.Dashboard6{background-color:#df1cd9} 
.DashboardBox.Dashboard6 aside span{color: #df1cd9;}
.DashboardBox.Dashboard7{background-color:#7843e1} 
.DashboardBox.Dashboard7 aside span{color:#ac70ba}
.DashboardBox.Dashboard8{background-color:#1c8edf} 
.DashboardBox.Dashboard8 aside span{color:#ac70ba}

.FilterArea{display: flex;justify-content: space-between;align-items: center; width: 100%;}
.FilterArea .form-group{margin: 0 10px 0px 0;display: inline-block;}
.FilterArea .form-group:last-child{margin: 0 0 0px 0;}
.FilterArea .form-group label{display:block;font-weight: 800;font-size: 13px;color: #000;}
.FilterArea .form-group .form-control{height:42px;line-height: 25px;padding:8px 10px;border: 1px solid #cbcaca;font-size: 13px;margin:0 10px 0 0;box-shadow:none;color: #3e3d3d;font-weight: 600;border-radius: 5px;}

.WrapperArea{margin-left: 250px;flex:1 1 auto;width: calc(100% - 265px); display:flex;flex-direction:column;margin-top:68px;transition:0.5s all ease-in-out}
.WrapperArea .WrapperBox{padding: 20px 5px;min-height: calc(100vh - 100px);}
/* .WrapperArea ::after {position: absolute; left: 0; top: 0; content: ""; width: 100px; height: 100px; background-color: red;} */
.Small-Wrapper{background-color:#fff;padding:25px;border:1px solid #dcdcdc;border-radius: 10px;margin: 0 0 20px 0;}

.TitleBox{display: flex;align-items: center; justify-content: space-between;margin: 0 0 20px 0;}
.TitleBox h4.Title {margin: 0;font-weight:600;text-transform:capitalize;font-size:22px;font-family: 'Roboto';background-image: linear-gradient(45deg,#fd701e,#fd894e);background-color: transparent;background-clip: text;-o-background-clip: text;-ms-background-clip: text;-moz-background-clip: text;-webkit-background-clip: text;-o-text-fill-color: transparent;-ms-text-fill-color: transparent;-moz-text-fill-color: transparent;-webkit-text-fill-color: transparent;background-image: linear-gradient(45deg,#4ecb71,#007621);}
.TitleBox a.TitleLink{background-image: linear-gradient(45deg,#fd701e,#fd894e);color:#fff;font-size:14px;font-weight: 600;padding: 10px 20px;border-radius: 5px;background: #4ecb71;}
.TitleBox a.TitleLink:hover{ background-color:#2dd65d }
.TitleBox a .fa-long-arrow-left{font-size: 22px;color: green;}

.saveBtn button.TitleLink{
    background-image: linear-gradient(45deg, rgb(5, 6, 6), rgb(22, 88, 133));
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;}
    .saveBtn button.TitleLink:hover{ background-color:rgb(31, 121, 180) }
.saveBtn button .fa-long-arrow-left{font-size: 22px;color:rgb(31, 121, 180);}

.TableList{ overflow: auto; } 
/* .TableList::-webkit-scrollbar-track{}
.TableList::-webkit-scrollbar-thumb{background: #4ecb71;transition: 0.5s all ease-in-out;border-radius: 10px;display: none;transition:background-color 0.2s linear,width 0.2s ease-in-out;-webkit-transition:background-color 0.2s linear,width 0.2s ease-in-out;}
.TableList::-webkit-scrollbar{width: 4px;border-radius: 20px;height: 5px;} */
/* .TableList:hover::-webkit-scrollbar-thumb{ display:block; } */
.TableList table{margin: 0 0 10px 0;}
.TableList table tr th{background-color:#dcdcdc;font-weight: 700; color:#000;font-size:14px;padding:10px 10px}
.TableList table tr td{font-weight: 600;color:#404040;padding:10px 10px;font-size: 13px;}
.TableList table tr td a {margin: 0 7px;text-decoration: underline;}
.TableList table tr td a:hover{color:#000}
.TableList table tr th:last-child,
.TableList table tr td:last-child{text-align:center;min-width:105px}
.TableList table tr td:last-child a{margin:0 5px;font-size:14px;display:inline-block}
.TableList table tr td:last-child a:hover{color:#000}

.TableList table tr:nth-child(even) {font-weight: 500;color: #7C8493;overflow: hidden;border-radius: 11px;}
.TableList table tr:nth-child(even) td{ background-color: #fff;}
.TableList table tr:nth-child(even) td:first-child{ border-radius: 10px 0 0 10px;}
.TableList table tr:nth-child(even) td:last-child{  border-radius: 0px 10px 10px 0px; }
.TableList table tr th:first-child{ border-radius: 10px 0 0 10px;}
.TableList table tr th:last-child{  border-radius: 0px 10px 10px 0px; background-image: none; }

.TableList table tr td span{padding: 8px 10px;color:#fff;border-radius:5px;width:auto;display: inline-block;text-align:center;font-size:12px;min-width: 100px;}
.TableList table tr td .Actions{display: flex;align-items: center;justify-content: center;}
.TableList table tr td .Actions button{padding: 6px 15px;color:#fff;border-radius: 25px;width:auto;display: inline-block;text-align:center;font-size: 13px;border: none;font-weight: 700;}
.TableList table tr td .Actions select{background-color: #fff;padding: 7px 6px;border-radius: 5px;color: #000;font-size: 14px;font-weight: 600;border: 1px solid #6e6e6e;outline: 0;}
.TableList table tr td .Actions a.Resend{padding: 5px 15px;color: #fff;border-radius: 5px;width: auto;display: block;text-align: center;font-size: 13px;background-color: #fd7325;}

.TableList table tr td a.Green{ color: #37a713; }
.TableList table tr td a.Red{ color: #e21818; }
.TableList table tr td a.Blue{ color: #139bbf; }
.TableList table tr td a.Yellow{ color: #feca05; }
.TableList table tr td a.Pink{color: #fd546d;}

.TableList table tr td span.Green{ background-color: #37a713; }
.TableList table tr td span.Blue{ background-color: #139bbf; }
.TableList table tr td span.Red{ background-color: #bb0f0f; }
.TableList table tr td span.Orange{background-color: #fd813f;}
.TableList table tr td span.Yellow{background-color: #feca05; color: #000; }
.TableList table tr td span.Gray{background-color: #00000040; color: #000000;}
.TableList table tr td span.Red{color:  white}

.TableList table tr td .Actions button.Green{ background-color: #37a713; }
.TableList table tr td .Actions button.Blue{ background-color: #139bbf; }
.TableList table tr td .Actions button.Red{ background-color: #bb0f0f; }
.TableList table tr td .Actions button.Orange{background-color: #df9919; }

.TableList table tr td .form-control{height:auto;padding: 6px 10px 6px 10px;border:1px solid #b3b3b3;border-radius:5px;box-shadow:none;font-weight:600;font-size:14px}

.DetailsArea{box-shadow:0 1px 4px #ddd;padding:20px;margin:0;border-radius:10px;display: flex;justify-content: space-between;}
.DetailsArea .DetailsProfile figure{overflow: hidden;width: 150px;}
.DetailsArea .DetailsProfile figure img{ width: 100%; } 
.DetailsArea .DetailsContent {width:100%;display: flex;}
.DetailsArea .DetailsContent aside{ width:50% }
.DetailsArea .DetailsContent h5{font-size: 18px;margin: 0 0 15px 0;font-weight: 700;color: #000;}
.DetailsArea .DetailsContent p{ margin:0 0 15px 0  }
.DetailsArea .DetailsContent p label{font-weight: 700;text-transform:capitalize;width: 150px;display:inline-block;position:relative;margin:0 15px 0 0;padding:0 10px 0 0;color: #000;font-size: 14px;}
.DetailsArea .DetailsContent p label a{ margin-left: 5px; }
.DetailsArea .DetailsContent p label:after{content:':';position:absolute;right:0;top:0} 
.DetailsArea .DetailsContent p span{font-weight: 600;text-transform:capitalize;color: #5e5c5c;font-size: 14px;}
.DetailsArea .DetailsContent p span a{ color:#000; margin:0 0 0 10px }

.BuildingArea .BuildingPackage{background-color: #fff;padding: 20px;margin: 0 0 20px 0;border-radius: 10px;box-shadow: 0px 0px 10px #ddd;height: calc(100% - 20px);}
.BuildingArea .BuildingPackage:last-child{}
.BuildingArea .BuildingPackage h4{margin: 0 0 15px 0;font-size: 18px;font-weight: 700;color: #000;}
.BuildingArea .BuildingPackage p{margin:0 0 20px 0;display: flex;}
.BuildingArea .BuildingPackage p:last-child{ margin:0 }
.BuildingArea .BuildingPackage p label{font-weight: 700;text-transform: capitalize;width: 150px;display: inline-block;position: relative;margin: 0 15px 0 0;padding: 0 10px 0 0;color: #000;font-size: 13px;}
.BuildingArea .BuildingPackage p label a{ margin-left: 5px; }
.BuildingArea .BuildingPackage p label:after{content:':';position:absolute;right:0;top:0} 
.BuildingArea .BuildingPackage p span{font-weight: 600;text-transform: capitalize;color: #5e5c5c;font-size: 13px;width: calc(100% - 175px);}
.BuildingArea .BuildingPackage p span a{color: #1a7cd1;margin:0 0 0 10px;font-weight: 600;}
.BuildingArea .BuildingPackage .View{background: #fd7f3b;color: #ffffff;font-size: 13px;font-weight: 700;padding: 6px 10px;border-radius: 5px;display: inline-block;border: 2px solid #fd7f3b;float: right;margin: -10px 0 0px 10px;}
.BuildingArea .BuildingPackage .BuildingPhotos ul li{display: inline-block;width: 175px;margin: 0 10px 10px 0;}
.BuildingArea .BuildingPackage .BuildingPhotos ul li figure{margin: 0;border: 3px solid #fff;box-shadow: 0px 0px 4px #ddd;border-radius: 10px;overflow: hidden;}
.BuildingArea .BuildingPackage .BuildingFeedBox h4{margin:0;display: inline-block;min-width: 300px;}
.BuildingArea .BuildingPackage .BuildingFeedBox+.BuildingFeedBox{ margin-top:20px }



.Filter {
    display: flex;
}
.Filter .form-group {
    margin: 0px 10px 20px 0px;
    display: inline-block;
}

.Filter .form-group:last-child {
    margin: 0px 0px 20px;
}

.Filter .form-group label {
    display: block;
    font-weight: 800;
    font-size: 14px;
    color: rgb(0, 0, 0);
}

.Filter .form-group .form-control {
    height: 42px;
    line-height: 25px;
    padding: 8px 10px;
    border: 1px solid rgb(203, 202, 202);
    font-size: 12px;
    margin: 0px 10px 0px 0px;
    box-shadow: none;
    color: rgb(62, 61, 61);
    font-weight: 600;
    border-radius: 5px;
    appearance: auto;
}


.HotelLocation {text-align: left;}

.HotelLocation aside h3 {
    font-size: 14px;
    color: #000;
    font-weight: 700
}

.HotelLocation aside p {
    font-size: 13px;
    font-weight: 500;
    color: #000
}

.HotelLocation aside p span {
    background-color: #fff;
    width: 15px;
    height: 15px;
    display: inline-block;
    text-align: center;
    border-radius: 50px;
    line-height: 25px;
    margin: 0 6px 0 0
}





.Overview {
    background: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 10px;
    margin: 0px 0px 20px;
    border-width: 1px 1px 1px 5px;
    border-style: solid;
    border-color: rgb(221, 221, 221) rgb(221, 221, 221) rgb(221, 221, 221) rgb(185, 185, 185);
    border-image: initial;
    box-shadow: rgb(221, 221, 221) 0px 0px 10px;
}

.Overview h2 {
    font-size: 15px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    margin-bottom: 20px;
    min-height: 40px;
}

.Overview aside {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Overview aside span {
    width: 50px;
    height: 50px;
    background-color: rgb(221, 221, 221);
    border-radius: 50px;
    text-align: center;
    line-height: 50px;
}

.Overview aside i {
    font-size: 18px;
    color: rgb(0, 0, 0);
}

.Overview aside h3 {
    font-size: 25px;
    color: rgb(0, 0, 0);
    font-weight: 800;
}

.TableList {
    overflow: auto;
    text-align: center;
}

.TableList table {
    border: 1px solid rgb(220, 220, 220);
}

.TableList table tr:nth-child(2n+2) {
    background-color: rgb(245, 245, 245);
}

.TableList table tr:hover {
    background: rgb(199, 199, 199);
}

.TableList table tr th {
    background-color: rgb(231, 230, 230);
    font-weight: 700;
    text-transform: capitalize;
    color: black;
    font-size: 14px;
    padding: 10px 13px;
    text-align: left;
}

.TableList table tr td {
    font-weight: 600;
    color: rgb(0, 0, 0);
    font-size: 13px;
    text-align: left;
    padding: 10px 13px;
}

.TableList table tr td a:hover {
    color: rgb(0, 0, 0);
}

.TableList table tr th:last-child, .TableList table tr td:last-child {
    text-align: center;
    min-width: 14px;
}


.TableList table tr td span {
    padding: 5px 10px;
    color: #165885;
    border-radius: 5px;
    width: auto;
    display: inline-block;
    text-align: center;
    font-size: 12px;
}

.TableList table tr td .Actions {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

td, th {
    padding: 8px;
}

.TableList table tr td a.Green {
    color: rgb(55, 167, 19);
}

.TableList table tr td a.Red {
    color: rgb(198, 32, 32) !important;
}

.TableList table tr td a.Blue {
    color: rgb(14, 81, 141) !important;
}

.TableList table tr td a.Yellow {
    color: rgb(254, 202, 5);
}

.TableList table tr td a.Pink {
    color: rgb(253, 84, 109);
}

.TableList table tr td span.Green {
    background-color: rgb(55, 167, 19);
    color: white;
}

.TableList table tr td span.Blue {
    background-color: rgb(14, 81, 141);
}

.TableList table tr td span.Red {
    background-color: rgb(187, 15, 15);
}

.TableList table tr td span.Orange {
    background-color: rgb(223, 153, 25);
}

.TableList tr td button {
    border: 1px solid rgb(145, 140, 140);
    padding: 2px 6px;
    font-size: 12px;
    color: white;
    background: rgb(145, 140, 140);
    border-radius: 5px;
}

.PannelOne {
    cursor: pointer;
    position: relative;
    padding: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.PannelOne h4 {
    font-weight: 600;
    font-size: 17px;
    color: rgb(0, 0, 0);
    background-color: rgb(233, 233, 233);
    padding: 15px;
    margin: -20px -20px 20px;
}

.PannelOne span {
    padding-right: 10px;
    position: absolute;
    top: 20%;
    right: 2%;
    font-size: 15px;
}

.GuestsArea {
    display: flex;
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    background-color: rgb(255, 255, 255);
    justify-content: space-between;
    padding: 11px;
}

.GuestsArea .GuestsLeft {width: 150px;}

.GuestsArea .GuestsLeft figure {
    margin: 0px;
    width: 100%;
    height: 80px;
    overflow: hidden;
    border-radius: 8px;
}

.GuestsArea .GuestsRight {
    width: calc(100% - 150px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 0 0 0 15px;
}

.GuestsArea .GuestsRight aside {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.GuestsArea .GuestsRight aside a {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    text-align: left;
    line-height: normal;
}

.GuestsArea .GuestsRight aside button {
    font-weight: 700;
    color: rgb(255, 255, 255);
    text-align: center;
    line-height: normal;
    padding: 2px 10px;
    border-radius: 5px;
    background-color: rgb(99, 161, 3);
    border: 2px solid rgb(99, 161, 3);
    font-size: 12px;
}

.GuestsArea .GuestsRight article {
    display: flex;
    align-items: baseline;
}

.GuestsArea .GuestsRight article span {
    width: 15px;
    display: inline-block;
    margin: 0px 4px 0px 0px;
}

.GuestsArea .GuestsRight article p {
    font-size: 13px;
    font-weight: 700;
    color: #8b8b8b;
}

.GuestsArea .GuestsRight label {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 0;
    text-decoration: none;
}

.GuestsArea .GuestsRight label a {
    font-size: 13px;
    color: #63a103;
    font-weight: 700;
}

/* .Subscriptions {
    padding: 20px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
}

.Subscriptions aside {
}

.Subscriptions aside h2 {
    font-size: 18px;
    text-align: center;
    line-height: 20px;
    font-weight: 700;
    margin: 0 0 7px 0;
}

.Subscriptions aside h3 {
    font-size: 20px;
    color: green;
    font-weight: 800;
    text-align: center;
    line-height: 20px;
    margin: 0 0 10px 0;
}

.Subscriptions aside p {
    font-size: 13px;
    color: #333333;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
}

.Subscriptions aside ul {
    display: flex;
    justify-content: center;
    margin: 10px 0px 0px;
}

.Subscriptions aside ul li {
    margin: 0 10px;
    font-size: 18px;
}

.Subscriptions aside ul li a {
} */

label {
    color: #000;
}

.form-group textarea:focus {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    border-color: rgb(0, 0, 0);
    outline: 0px;
    box-shadow: none;
    font-size: 12px;
}


.CommonLinksNew {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 10px;
}

.CommonLinks a{
    color: black;
    font-size: 18px;
    font-weight: 700;
}
.back {
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 16px;
    color: rgb(0, 0, 0);
}

/* .Subscription-cancel {
    margin: 10px 0px 20px;
    display: flex;
    justify-content: space-between;
}

.Subscription-cancel aside{

display: flex;
}
.Subscription-cancel aside a{
    border: 1px solid #dbdbdb;
    padding: 15px;
    background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    margin: 0px 10px 0px 0px;
    color: #165885;
    font-size: 14px;
    font-weight: 700;
}
.Subscription-cancel aside a .fa{

margin: 0px 0px 0px 20px;
}

.Subscription-cancel ul li {
    border: 1px solid #dbdbdb;
    padding: 15px;
    background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    margin: 0px 10px 0px 0px;
}

.Subscription-cancel ul li a {
    color: rgb(0, 0, 0);
    display: block;
    font-weight: 600;
    font-size: 15px;
    margin: 0px 15px 0px 0px;
} */

.UserBox .UserHead {
    display: flex;
    margin: 0px 0px 15px;
    align-items: center;
}

.UserBox .UserHead figure {
    width: 50px;
    margin: 0px 15px 0px 0px;
    border-radius: 50%;
    border: 1px solid black;
}

.UserBox .UserHead figure img {
    border-radius: 50%;
}

.UserBox .UserHead figcaption {
    width: calc(100% - 65px);
}

.UserBox .UserHead figcaption h3 {
    font-weight: 700;
    font-size: 16px;
    margin: 0px 0px 5px;
}

.UserBox .UserBody {
    display: flex;
    justify-content: space-between;
}

.UserBox .UserBody aside {
    width: 50%;
}

.UserBox .UserBody aside p {
    font-size: 14px;
    margin: 0px 0px 10px;
    width: 100%;
    display: flex;
    align-items: baseline;
    font-family: Mulish;
}

.UserBox .UserBody aside p:last-child{ margin:0 }

.UserBox .UserBody aside p label {
    margin: 0px 15px 0px 0px;
    width: 165px;
    position: relative;
    color: rgb(0, 0, 0);
    font-weight: 700;
}

.UserBox .UserBody aside p label::after {
    content: ":";
    position: absolute;
    right: 0px;
    top: 0px;
}

.UserBox .UserBody aside p span {
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    color: green;
}

.UserBox {
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #dddddd;
    background: #fbfbfb;
}

.UserHead h6 {
    font-size: 14px;
    margin: 0 0 6px 0;
    color: #838181;
    font-weight: 600;
}

.cust-details {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0px 0px 5px;
    font-size: 15px;
    font-weight: 600;
    color: black;
    align-items: baseline;
}

.left-details {
    display: flex;
}

.left-details img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

.right-details p {font-size: 16px;color: #000;margin: 0 0 4px 0;font-weight: 700;}

.back {
    margin: 0px 0px 0px 10px;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 16px;
    color: rgb(0, 0, 0);
}

.UserHead h6 span {
    width: 15px;
    display: inline-block;
    margin: 0px 5px 0px 0px;
}

.UserBody figure {
    width: 180px;
    border: 5px solid #fff;
    border-radius: 5px;
    margin: 0px 15px 0px 0px;
    box-shadow: 0px 0px 10px #b7b7b7;
}

.UserBody article {
}

.UserBody article a {
    color: green;
    text-decoration: underline;
    line-height: 30px;
    font-size: 15px;
    font-weight: 800;
}

.UserBody article p {
    font-size: 14px;
    font-weight: 600;
    color: green;
}

.UserBody article p a {
    color: black;
}

.UserBody article p span {
}

.UserBody article p span img {
    margin: 0px 4px 4px 0px;
}

.partner-details {
}

.partner-details ul {
    display: flex;
    margin: 20px 0px;
    padding: 0;
    flex-wrap: wrap;
}

.partner-details ul li {
 
background-color: #ddd;
 
border: 1px solid #ddd;
 
border-radius: 10px;
 
width: calc(100% / 5 - 10px);
 
margin: 0 5px 15px;
}

.partner-details ul li a{
    border-radius: 9px;
    border: 1px solid rgb(225 224 224);
    background-color: rgb(255, 255, 255);
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.partner-details ul li .partner-services {
    padding: 15px;
    text-align: center;
}
.partner-details ul li .partner-services p{
    color: black;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 10px;
}

.partner-details ul li .partner-services figure {
    width: 60px;
    margin: auto;
}

.partner-services figure {
}

.partner-services figcaption {
    padding: 8px;
}

.partner-services figcaption h3 {
    font-weight: 600;
    color: rgb(51, 51, 51);
    text-align: center;
    font-size: 14px;
    line-height: 20px;
}

.total-order h3 {
    font-size: 16px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    margin: 25px 0px 6px;
}

.total-order+.total-order{ margin-top:15px }

.order-address {
    border: 1px solid #d7d7d7;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-radius: 10px;
    background: #f5f5f5;
}

.order-address+.order-address { margin-top:15px }

.order-address-body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.order-address-footer {
}

.order-address-body a {
    text-decoration: underline;
    font-weight: 700;
    /* color: #000; */
    font-size: 14px;
}

.order-address p {
    color: #333333;
    line-height: 21px;
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 7px 0;
}

.order-address h4 {
    font-size: 15px;
    font-weight: 700;
}

.order-address h4 span {
    color: green;
}

.order-address-footer .Cancellation {
    border: 1px solid rgb(221, 221, 221);
    color: black;
    text-decoration: none;
    padding: 4px 15px;
    display: inline-block;
    margin-top: 10px;
    border-radius: 5px;
    background: rgb(221, 221, 221);
    font-size: 12px;
    font-weight: 500;
}

.UserHead h3 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin: 0 0 7px 0;
}

.panel-body ul li .panel-item aside h2 {
    color: rgb(85, 85, 85);
    font-size: 15px !important;
    line-height: 18px !important;
}

.panel-body ul li .panel-item aside p {
    color: green !important;
    font-weight: 600 !important;
}

.panel-body ul li .panel-item aside p span {
    margin: 0px 5px 0px 0px;
}

.panel-body ul li .panel-item a {
    position: absolute;
    top: 0px;
    right: 0px;
    color: black;
    font-size: 16px;
}

/* .subscription {
    background: #f9f9f9;
    border-radius: 10px;
    border: 1px solid #dddddd;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 20px;
} */
.review-rating{
    background: #f9f9f9;
    border-radius: 10px;
    border: 1px solid #dddddd;
    margin-top: 20px;
    padding: 20px;
}
.review-rating aside.reply-left{
   
}
.review-rating aside.reply-right{
    margin: 20px 0px 0px 50px;
}
.review-rating aside.reply-right .reply-body{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.review-rating p {
    line-height: 20px;
}
.review-rating .peraDate {
    text-align: right;
    font-size: 12px;
    color: black;
}
.review-rating a{display: block;
    text-align: right;
    text-decoration: underline;
}

.comn-item img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 18px;
}

.comn-item {
    display: flex;
    align-items: center;
}

.comn-item h2 {
    font-size: 15px;
}

.comn-item span {
    color: rgb(22, 88, 133);
    margin: 0px 0px 0px 12px;
}

.order-address-body .UserHead {
    display: flex;
    align-items: center;
}

.order-address-body .UserHead figure {
    width: 180px;
    border-radius: 10px;
    margin: 0px 15px 0px 0px;
    overflow: hidden;
    border: 5px solid #fff;
    box-shadow: 0px 0px 12px #c5c5c5;
}

.order-address-footer h3 {
    color: green;
    font-size: 14px;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.pagination a {
    color: black;
    float: left;
    padding: 2px 6px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
}

.pagination a.active {
    background-color: rgb(22, 88, 133);
    color: white;
    border-radius: 5px;
}
.pagination>.active>a{
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
}
.earning {
    text-align: right;
    margin: 8px 0px;
}

.earning p {
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
}

.earning p span {
    color: green;
}

.earning a {
    font-size: 18px;
    font-weight: 700;
    color: #3bd10a;
    text-decoration: underline;
    font-style: italic;
}

.earning2 p {
    line-height: 22px;
}

.earning2 a {
    text-decoration: none;
}
.earning2 {
    display: flex;
    justify-content: space-between;
}
.saveBtn {
    padding: 0px 0px 25px;
    margin: 40px 0px 0px;
    text-align: center;
}

.ck-content {
    min-height: 200px;
}

/*.ModalBox .modal-dialog {
    max-width: 450px;
    margin: 50px auto;
}

.ModalBox .modal-dialog .Category {
    padding: 10px;
}

.ModalBox .modal-dialog .CloseModal {
    position: absolute;
    top: -13px;
    right: -13px;
    width: 26px;
    height: 26px;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgba(0, 0, 0, 0.5);
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 15px;
    border-radius: 50%;
}

.ModalBox .modal-dialog .Decline h3 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 22px;
    margin: 0px 0px 5px;
    text-align: left;
}

.ModalBox .modal-dialog .Decline p {
    font-size: 16px;
    font-family: Roboto;
    color: rgb(119, 119, 119);
    text-align: left;
}

.ModalBox .modal-dialog .Decline h4 {
    margin: 15px 0px 0px;
    text-align: right;
}

.ModalBox .modal-dialog .Decline h4 a {
    text-transform: capitalize;
    margin: 0px 0px 0px 30px;
    font-size: 17px;
    color: rgb(0, 0, 0);
    font-weight: 500;
    transition: all 0.5s ease-in-out 0s;
}

.ModalBox .modal-dialog .Category h3 {
    text-align: center;
    font-weight: 700;
    margin: 0px 0px 20px;
    border-bottom: 1px solid rgb(221, 221, 221);
    padding: 0px 0px 15px;
    font-size: 18px;
}
*/
.notification-btn {
    display: flex;
    justify-content: space-between;
}

.notification-btn .active {
    background: rgb(0, 0, 0) !important;
}

.notification-btn .active:hover {
    background: rgb(22, 88, 133);
}

.CommonLinks ul li {
    margin: 0px 10px 0px 0px;
    display: inline-block;
}

.CommonLinks ul li a {
    background-color: rgb(208, 206, 206);
    border-radius: 5px;
    padding: 10px 20px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    cursor: pointer;
    text-transform: capitalize;
    margin: 0px;
    font-size: 15px;
    display: block;
    border: none !important;
}

.CommonLinks ul li.active a {
    color: rgb(255, 255, 255);
    background-image: linear-gradient(45deg, rgb(22, 88, 133), rgb(22, 88, 133));
}

.CommonTabs .nav-tabs li {
    margin: 0px 15px 0px 0px;
}

.CommonTabs {
    margin-bottom: 20px;
}

.CommonTabs .nav-tabs {
    border: none;
}

.CommonTabs .nav-tabs li {
    margin: 0px 15px 0px 0px;
}

.CommonTabs .nav-tabs li a {
    background-color: rgb(208, 206, 206);
    border-radius: 5px;
    padding: 11px 35px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    cursor: pointer;
    text-transform: capitalize;
    margin: 0px;
    font-size: 15px;
    border: none !important;
}

.CommonTabs .nav-tabs li a.active {
    background-image: linear-gradient(45deg, rgb(249, 140, 28), rgb(251, 185, 20));
    color: rgb(255, 255, 255);
}

.CommonTabs .nav-tabs li a.active {
    color: rgb(255, 255, 255);
    background-image: linear-gradient(45deg, rgb(22, 88, 133), rgb(22, 88, 133));
}

.CommonTabs .nav-tabs li a {
    border-radius: 5px;
    padding: 10px 20px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    cursor: pointer;
    text-transform: capitalize;
    margin: 0px;
    font-size: 15px;
    border: none !important;
}

.SearchBox {
    margin: 0px;
    float: right;
    display: flex;
}

.SearchBox form {
    margin: 0px 10px 0px 0px;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
}

.SearchBox form input {
    margin: 0px;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: none;
    outline: 0px;
    padding: 0px 13px;
    height: 43px;
    min-width: 190px;
    border-radius: 5px 0px 0px 5px;
    font-weight: 600;
}

.SearchBox form button {
    border-radius: 0px;
}

.MenuList ul li {
    background: rgb(245, 245, 245);
    padding: 20px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    border: 1px solid rgb(209, 209, 209);
    justify-content: space-between;
    margin-bottom: 10px;
    
}
.MenuList ul.createBill li{
    margin: 0px 12px 12px 0;
    width: 45%;
}

.MenuList ul.createBill li .MenuLeft figcaption{width: 290px;}
.MenuList ul li .MenuLeft {
    display: flex;
    align-items: center;
   
}

.MenuList ul li .MenuLeft figure {
    margin: 0px;
    width: 70px;
    border: 4px solid rgb(255, 255, 255);
    border-radius: 50%;
}

.MenuList ul li .MenuLeft figcaption {
    padding: 0px 0px 0px 15px;
}

.MenuList ul li .MenuLeft figcaption h3 {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    margin: 0px 0px 6px;
}

.MenuList ul li .MenuLeft figcaption h4 {
    font-size: 12px;
    font-weight: 700;
    margin: 0px 0px 5px;
}
/* .MenuList ul li .MenuMiddle figcaption h4{
    font-size: 12px;
    font-weight: 700;
    margin: 0px 0px 5px;
} */

.MenuList ul li .MenuLeft figcaption h4 span {
    margin: 0px 60px 0px 0px;
}
.MenuList ul li .MenuLeft figcaption h5{
    font-size: 14px;
    font-weight: 700;
}
.MenuList ul li .MenuLeft figcaption p {
    font-size: 14px;
    font-weight: 800;
    margin: 5px 0px;
}

.MenuList ul li .MenuRight {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    /* width: 30%; */
}

.MenuList ul li .MenuRight h5 {
    font-weight: 600;
    margin: 0px 16px 0px 0px;
    font-size: 16px;
}

.MenuList ul li .MenuRight h5 span {
    display: block;
    font-size: 12px;
    color: green;
}
.MenuList a i {
    font-size: 20px;
}
.waiveoff {
   
    padding: top 15px;
    padding-left: -10px;
}

.orderReq .order-head {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.orderReq .order-head figcaption {
}

.orderReq .order-head figcaption h4 {
    font-size: 15px;
    font-weight: 600;
    color: green;
}

.orderReq .order-head figcaption p {
    font-size: 14px;
    margin: 0 0 6px 0;
}

.orderReq .order-head aside {
}

/*.ModalBox .modal-dialog .Decline {
    margin: 0px;
    padding: 10px;
}

.ModalBox .modal-dialog .Decline h3 {
    font-weight: 700;
    font-size: 20px;
    margin: 0px 0px 15px;
    color: black;
}

.ModalBox .modal-dialog .Decline p {
    font-size: 15px;
    color: rgb(66, 64, 64);
    text-align: left;
}

.ModalBox .modal-dialog .Decline h4 {
    margin: 15px 0px 0px;
    text-align: right;
}*/

.ModalBox .modal-dialog .Decline figure {
    width: 100px;
    margin: auto;
    padding: 20px 0px;
}

.ModalBox .modal-dialog .Decline .Button {
    width: 100%;
}

.AddOrder{
    background: #165885;
    color: #fff;
    border: none;
    padding: 7px 20px;
    border-radius: 5px;
    outline: 0;
}

.MenuRight .silder-right {
    display: flex;
    border: 2px solid rgb(22, 88, 133);
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    flex-direction: row-reverse;
}

.MenuRight .silder-right button {
    width: 30px;
    height: 28px;
    background-color: rgb(22, 88, 133);
    border: none;
    color: rgb(255, 255, 255);
    font-size: 20px;
    line-height: 22px;
    outline: 0px;
}

.MenuRight .silder-right input {
    padding: 0px;
    border: none;
    width: 40px;
    height: 27px;
    text-align: center;
    outline: 0px;
}

.order-status-body {
    text-align: center;
    padding: 0px 250px;
}

.order-status-body .order-status {
}

.order-status-body .order-status figure {
    width: 70px;
    margin: auto;
}

.order-status-body .order-status figcaption {
    color: green;
    margin: 20px 0px;
    font-weight: 600;
    font-size: 16px;
}

.order-status-body .qr-code {
}

.order-status-body .qr-code figure {
    width: 140px;
    margin: auto;
    padding: 40px 0px;
}

.order-status-body .qr-code figcaption {
}

.order-status-body .order-status figcaption h3 {
    font-size: 18px;
    line-height: 29px;
    font-weight: 700;
}

.StaticBoxArea .panel-group {
    margin: 0px;
}

.StaticBoxArea .panel-group .panel {
    border: 1px solid rgb(233, 233, 233);
    border-radius: 0px;
}

.StaticBoxArea .panel-group .panel + .panel {
    margin: 20px 0px 0px;
}

.StaticBoxArea .panel-group .panel .panel-heading {
    cursor: pointer;
    background-color: rgb(233, 233, 233);
    border-radius: 0px;
    padding: 15px 55px 15px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.StaticBoxArea .panel-group .panel .panel-heading::after {
    content: '\f106';
    font-family: FontAwesome;
    color: rgb(0, 0, 0);
    position: absolute;
    top: 18px;
    right: 20px;
    font-size: 19px;
    font-weight: 600;
    line-height: 14px;
    transition: all 0.5s ease-in-out 0s;
}

.StaticBoxArea .panel-group .panel .panel-heading[aria-expanded="true"]::after {
    transform: rotate(180deg);
}

.StaticBoxArea .panel-group .panel .panel-heading h4 {
    font-size: 17px;
    color: rgb(0, 0, 0);
    font-weight: 700;
}

.StaticBoxArea .panel-group .panel .panel-heading h4 span {
    padding-right: 10px;
}

.StaticBoxArea .panel-group .panel .panel-heading a {
    color: rgb(22, 88, 133);
    font-size: 14px;
}

.StaticBoxArea .panel-group .panel .panel-body {
    padding: 15px;
}

.StaticBoxArea .panel-group .panel .panel-body h3 {
    color: rgb(22, 88, 133);
    font-size: 16px;
}

.StaticBoxArea .panel-group .panel .panel-body::before {
    content: none;
}

.StaticBoxArea .panel-group .panel .panel-body p {
    font-size: 14px;
    font-weight: 400;
    color: rgb(64, 64, 64);
    line-height: 22px;
}

.StaticBoxArea .panel-group .panel .panel-body p + p {
    margin-top: 15px;
}

.StaticBoxArea .panel-group .panel .panel-body h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 50px;
}

.StaticBoxArea .panel-group .panel .panel-body .panel-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.StaticBoxArea .panel-group .panel .panel-body .panel-item figure {
    width: 60px;
    margin: 0px;
}

.StaticBoxArea .panel-group .panel .panel-body .panel-item aside {
    margin: 0px 0px 0px 15px;
}

.StaticBoxArea .panel-group .panel .panel-body ul {
    display: flex;
}

.StaticBoxArea .panel-group .panel .panel-body ul li {
    width: 50%;
    border-radius: 5px;
    border: 1px solid rgb(237 237 237);
    padding: 10px;
    margin: 0 15px 0 0;
}

.qr-code-otp form {
    margin: auto;
}


.qr-code-otp form .form-group {
    margin: 0px 0px 20px;
    position: relative;
}

.qr-code-otp form .form-group .OTPBox {
    display: flex;
    justify-content: center;
    margin: 0px 0px 10px;
}

.qr-code-otp form .form-group .OTPBox .form-control {
    padding: 0px;
    text-align: center;
    width: 60px;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
    color: black;
    line-height: 30px;
    border: 1px solid #b7b7b7;
    margin: 0 10px;
}
.message {
    display: flex;
    justify-content: space-between;
}

.message aside {
    display: flex;
    align-items: center;
}

.message aside figure {
    width: 50px;
    height: 50px;
    border: 1px solid gray;
    border-radius: 50%;
    margin: 0px 12px 0px 0px;
    overflow: hidden;
}

.message aside figcaption {
    font-size: 15px;
}
.message aside figcaption a{color: #4ba7e5;}
.message article {
    display: flex;
    align-items: center;
}

.message article figure {
    margin: 0px;
}

.message article figcaption {
    margin: 0px 0px 0px 12px;
    font-size: 14px;
}

.message article figcaption a {
    color: green;
    font-weight: 700;
}

.Decline .MenuList ul li {
    padding: 8px;
}

.Decline .MenuList ul li .MenuLeft figure {
    width: 50px;
    height: 50px;
    padding: 0px;
}

.Decline .MenuList ul li .MenuLeft figcaption h3 {
    font-size: 14px;
    margin: 0px;
}

.Decline .MenuList ul li .MenuLeft figcaption p {
    font-size: 12px;
}

.Decline .MenuList ul li .MenuLeft figcaption h4 {
    text-align: left;
    margin: 0px;
}

.Decline .MenuList ul li .MenuRight figcaption h5 {
    font-size: 12px;
}

.Book {
    border: 1px solid gray;
    padding: 3px 10px;
    border-radius: 5px;
    color: white;
    background: black;
}

.Book:hover {
    background-color: rgb(14, 81, 141);
    color: white;
}

.AmenitiesArea ul li .AmenitiesBox p {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    color: black;
}
.AmenitiesArea ul li .AmenitiesBox p span{
    margin: 0px 8px 0px 0px;
    display: inherit;
}

.AmenitiesArea2 ul li {
    width: calc(50%) !important;
}

.AmenitiesArea .AmenitiesBox figcaption a.add {
    padding: 3px 20px;
    font-size: 12px;
    margin-top: 10px;
}

.guest-menuList .MenuRight {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.guest-menuList .MenuRight p a {
    text-decoration: underline;
    font-weight: 700;
}

.TitleBox ul {
    display: flex;
}

.TitleBox .add {
    background: rgb(22, 88, 133);
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 4px;
    display: inline-block;
}

.TitleBox ul li {
    margin: 0px 0px 0px 10px;
}

span.icon {
    width: 80px;
    display: inline-block;
}
.total-bill{margin: 12px 0px 0px 0px;}
.total-bill h3{
    font-size: 18px;
    font-weight: 700;
    color: black;
}
.total-bill p{
    color: black;
    font-size: 14px;
    line-height: 25px;
}
.HotelLocation2{
    display: flex;justify-content: space-between;
}

.CheckBox {
    display: inline-block;
    position: relative;
    padding-left: 33px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    font-weight: 600 !important;
    color: #2d2d2d;
    margin: 0 !important;
    min-height: 20px;
}
.CheckBox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.CheckBox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 24px;
    background-color: transparent;
    border: 1px solid #165885;
    border-radius: 2px;
}
.SupportDetails {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;    
}
.SupportDetails ul {
    max-height: 180px;
    overflow: auto;
}
.SupportDetails ul li {
    margin-bottom: 20px;
    display: flex;
}
.SupportDetails ul li figure {
    color: #4d5052;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    font-size: .875rem;
    user-select: none;
    overflow: hidden;
    margin: 0 10px 0 0;
}
.SupportDetails ul li figcaption {
    display: inline-block;
    width: calc(100% - 50px);
    max-width: 850px;
}
.SupportDetails ul li figcaption h3 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin: 0 0 5px 0;
}
.SupportDetails ul li figcaption h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 5px 0;
}
.SupportDetails ul li figcaption p {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 5px 0;
}
.SupportDetails ul li figcaption span.time {
    display: block;
    font-weight: 700;
    color: #565656;
    font-size: 12px;
}
.SupportDetails ul li {
    margin-bottom: 20px;
    display: flex;
}
.SupportDetails ul li.OtherMessage {
    flex-direction: row-reverse;
}
.SupportDetails ul li figure {
    color: #4d5052;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    font-size: .875rem;
    user-select: none;
    overflow: hidden;
    margin: 0 10px 0 0;
}
.SupportDetails ul li.OtherMessage figure {
    margin: 0 0 0 10px;
}
.SupportDetails ul li figcaption {
    display: inline-block;
    width: calc(100% - 50px);
    max-width: 850px;
}
.SupportDetails ul li.OtherMessage figcaption {
    text-align: right;
}
.SupportDetails ul li figcaption h3 {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin: 0 0 5px 0;
}
.SupportDetails ul li figcaption p {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 5px 0;
}
.SupportDetails ul li figcaption span.time {
    display: block;
    font-weight: 700;
    color: #565656;
    font-size: 12px;
}
.MessageReply {
    border: 1px solid #ddd;
    display: flex;
    padding: 10px 15px;
    border-radius: 10px;
    align-items: flex-end;
    justify-content: flex-end;
}
.MessageReply .form-control {
    border: none;
    padding: 0;
    font-size: 15px;
    font-weight: 600;
    box-shadow: none;
    height: auto;
}
.MessageReply button {
    border: none;
    font-size: 15px;
    padding: 10px;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    background: white;
    position: relative;
    cursor: pointer;
}
.MessageReply button img{
    width: 20px;
}
.MessageReply input {
    position: absolute;
    top: 6px;
    left: 1px;
    opacity: 0;
    width: 100%;
}
.MessageReply .msg-send{
    position: absolute;
}



.NotificationsBox ul li+li{margin:15px 0 0 0}
.NotificationsBox ul li aside{background:#f9f9f9;padding:10px;border:1px solid #ddd;border-radius:5px}
.NotificationsBox ul li aside p{font-size:14px;font-weight:500;line-height:27px;margin:0 0 5px 0}
.NotificationsBox ul li aside h6{text-align:right;font-size:15px;font-weight:700;color:#000}

/* .Subscription-cancel {
    margin: 10px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */

/* .Subscription-cancel aside a {
    border: 1px solid #dbdbdb;
    background: #fff;
    border-radius: 6px;
    margin: 0px 10px 0px 0px;
    color: #6a6a6a;
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
} */




.NewTitleBox{display:flex;align-items:center;justify-content:space-between;margin:0 0 20px 0}
.NewTitleBox h4{margin:0;font-weight:800;text-transform:capitalize;font-size:22px;color:#165885}

.NewTitleBox .ExcelDownload{background-color:#007193}
.NewTitleBox .ExcelDownload i{margin-right:7px}

.HotelLocation{text-align: left;}
.HotelLocation aside h3{font-size:16px;color: #fff;font-weight:700}
.HotelLocation aside p{font-size:13px;font-weight:500;color: #fff;}
.HotelLocation aside p span{background-color:#fff;width: 14px;height: 14px;display:inline-block;text-align:center;border-radius:50px;line-height:25px;margin:0 6px 0 0}

.CouponsList .CouponsBox{background-color:#f3f3f3;border:1px solid #ddd;padding:15px;border-radius:10px}
.CouponsList .CouponsBox figure{background-color:#fff;padding:20px;border-radius:10px;box-shadow:0 0 4px #ddd}
.CouponsList .CouponsBox figure img{width:150px;margin:auto;display:block}
.CouponsList .CouponsBox figcaption h3{font-size:16px;font-weight:700;color:#000;margin:0 0 6px 0}
.CouponsList .CouponsBox figcaption h3 span{font-size:14px;color:#4e4e4e}
.CouponsList .CouponsBox figcaption h4{font-size:18px;font-weight:700;color:#609f0d;margin:0 0 5px 0}
.CouponsList .CouponsBox figcaption h6{margin:0 0 10px 0;font-weight:700;color:#000;font-size:14px}
.CouponsList .CouponsBox aside label{float:none}
.CouponsList .CouponsBox aside a{width:24px;display:inline-block;text-align:center}

.AmenitiesArea ul{display:flex;flex-wrap:wrap;margin:0 -10px}
.AmenitiesArea ul li{display:inline-block;width:calc(100% / 4);margin:0 0 20px;padding:0 10px}
.AmenitiesArea ul li .AmenitiesBox{padding:70px 20px 25px 20px;position:relative;overflow:hidden;text-align:center;background: #fff;border:1px solid #e8eef1;-webkit-transition:all 300ms linear;-o-transition:all 300ms linear;transition:all 300ms linear;border-radius:6px;-webkit-border-radius:6px;-moz-border-radius:6px;-ms-border-radius:6px;-o-border-radius:6px;-webkit-box-shadow:0 0 30px 0 rgb(0 0 0 / 5%);box-shadow:0 0 30px 0 rgb(0 0 0 / 5%);cursor:pointer;min-height:165px}
.AmenitiesArea ul li .AmenitiesBox aside{position:absolute;right:10px;top:10px}
.AmenitiesArea ul li .AmenitiesBox aside label{float:none;padding:0;width:40px;height:24px}
.AmenitiesArea ul li .AmenitiesBox aside a{width:30px;height:30px;display:inline-block;line-height:30px;border-radius:5px;margin:0 0 0 5px}
.AmenitiesArea ul li .AmenitiesBox aside a.Green{background-color:#00800021}
.AmenitiesArea ul li .AmenitiesBox aside a.Red{background-color:#ff000017}
.AmenitiesArea ul li .AmenitiesBox figure{width:60px;height:60px;margin:0 auto 15px}
.AmenitiesArea ul li .AmenitiesBox figure img{width:100%;height:100%}
.AmenitiesArea ul li .AmenitiesBox h6{margin:0;text-transform:capitalize;font-size:15px;font-weight:700;color: green;line-height:23px}

.WifiArea{display:flex;flex-wrap:wrap;margin:0 -10px}
.WifiArea .WifiBox{background-color:#f1f1f1;padding:15px;border:1px solid #ddd;border-radius:10px;width:calc(100% / 3 - 10px);margin:0 5px 10px}
.WifiArea .WifiBox aside{display:flex;justify-content:space-between;margin:0 0 15px 0;align-items: center;}
.WifiArea .WifiBox aside h4{font-size:18px;font-weight:700}
.WifiArea .WifiBox aside a{width:30px;height:30px;display:inline-block;line-height:30px;border-radius:5px;margin:0 0 0 5px;text-align:center}
.WifiArea .WifiBox aside a.Green{background-color:#00800021}
.WifiArea .WifiBox aside a.Red{background-color:#ff000017}
.WifiArea .WifiBox article{background-color:#fff;padding:15px;border-radius:10px}
.WifiArea .WifiBox article p{font-size:14px;margin:0 0 10px 0}
.WifiArea .WifiBox article p:last-child{ margin:0 }
.WifiArea .WifiBox article p label{margin:0 15px 0 0;width: 110px;position:relative;color:#000;font-weight:700}
.WifiArea .WifiBox article p label:after{content:':';position:absolute;right:0}
.WifiArea .WifiBox article p span{font-weight:600}

.WifiArea .WifiBox article p span input{
    background: transparent;
    border: none;
    outline: none;
    display: inline-block;
    width: 130px;
}

.WifiArea .WifiBox article p span+.Icon{}

.TrendingArea .TrendingBox{box-shadow:0 1px 4px #ddd;padding:20px;margin:0;border-radius:10px;display:flex;justify-content:space-between;background-color:#fff;margin:0 0 20px 0}
.TrendingArea .TrendingBox:last-child{margin:0}
.TrendingArea .TrendingBox article{display:flex}
.TrendingArea .TrendingBox article figure{margin:0;width:80px}
.TrendingArea .TrendingBox article figcaption{padding:0 0 0 20px}
.TrendingArea .TrendingBox article figcaption h3{font-weight:600;font-size:15px;color:#000;margin:0 0 7px 0}
.TrendingArea .TrendingBox article figcaption h4{font-size:14px;font-weight:700;margin:0 0 7px 0}
.TrendingArea .TrendingBox article figcaption h4 span{color:#29bd0e}
.TrendingArea .TrendingBox article figcaption h4 a{color:#4c4c4c;font-size:13px}
.TrendingArea .TrendingBox article figcaption p{font-size:12px;font-weight:700;color:#747474}
.TrendingArea .TrendingBox aside h5{color:#29bd0e;font-weight:700;font-size:18px;margin:0 0 5px 0}
.TrendingArea .TrendingBox aside h6{color:#000;font-weight:700;margin:0 0 10px 0}
.TrendingArea .TrendingBox aside button{background-color:#165885;color:#fff;font-size:14px;font-weight:600;padding:10px 20px;border-radius:5px;border:none;outline:0}

.RoomMiniBar article h5{font-weight:700;margin:0 0 20px 0;font-size:18px;color:#000}
.RoomMiniBar ul{display:flex;flex-wrap:wrap;margin:0 -7px}
.RoomMiniBar ul li{width:calc(100% / 2);padding:0 7px;margin:0 0 15px 0}
.RoomMiniBar ul li aside{display:flex;align-items:center;background-color:#fbfbfb;padding:20px;border:1px solid #ddd;border-radius:5px}
.RoomMiniBar ul li aside label{padding:0;width:25px;height:20px}
.RoomMiniBar ul li aside label .checkmark{background-color:#fff}
.RoomMiniBar ul li aside p{margin:0 10px;font-weight:700;color:#000;font-size:15px}
.RoomMiniBar ul li aside input{width:35px;height:35px;border:1px solid #ddd;border-radius:5px;text-align:center;font-size:14px;font-weight:700}
.RoomMiniBar .BarImages ol{display:flex;margin:0 -10px}
.RoomMiniBar .BarImages ol li{width:calc(100% /4);padding:0 8px}
.RoomMiniBar .BarImages ol li figure{margin:0;border:5px solid #fff;border-radius:10px;overflow:hidden;height:165px;box-shadow:0 0 10px #ddd}

.GuestsStayArea article{background-color:#f7f7f7;margin:0 0 20px 0;padding:20px;border:1px solid #ddd;border-radius:10px}
.GuestsStayArea article:last-child{margin:0}
.GuestsStayArea article h4{font-weight:700;color:#000;margin:0 0 15px 0;font-size:18px}
.GuestsStayArea article h4 a{color:#3bd10a;float:right;text-decoration:underline;font-style:italic}
.GuestsStayArea article ul{display:flex}
.GuestsStayArea article ul li{display:inline-block;margin:0 10px 0 0;min-width:300px}
.GuestsStayArea article ul li a{background-color:#fff;padding:20px 20px;height:100%;display:flex;align-items:center;justify-content:center;border:1px solid #ddd;border-radius:5px;min-height:120px}
.GuestsStayArea article ul li a h5{text-align:center;color:#000;font-weight:700;font-size:16px}
.GuestsStayArea article ul li a h5 span{display:block;font-size:12px;font-weight:600;color:#4c4c4c;margin:10px 0 0 0}

.GuestAccordian .card+.card{margin-top:20px}
.GuestAccordian .card .card-header{padding:15px;display:flex;justify-content:space-between;align-items:center;border-radius:0;border:none;cursor:pointer}
.GuestAccordian .card .card-header::after
{
    content: '\f106';
    font-family: FontAwesome;
    color: rgb(0, 0, 0);
    position: absolute;
    top: 18px;
    right: 20px;
    font-size: 19px;
    font-weight: 600;
    line-height: 14px;
    transition: all 0.5s ease-in-out 0s;
}
.GuestAccordian .card .card-header[aria-expanded="true"]::after {
    transform: rotate(180deg);
}
.GuestAccordian .card .card-header h5{font-weight:700;color:#3c3c3c;font-size:16px}
.GuestAccordian .card .card-header h5 strong{font-weight:700;color:#000}
.GuestAccordian .card .card-header h6{text-align: left;font-size:14px;font-weight:700;color:#000}
.GuestAccordian .card .card-header h6 span{display:block;color:#767676;padding-top: 10px;}
.GuestAccordian .card .card-body{border-top:1px solid #ddd}
.GuestAccordian .card  .Approve{display:inline-block;text-align:center;font-size:12px;color:#fff;font-weight:700;padding:8px 12px;border-radius:5px;margin:0 0 0 10px;border:none;background:#3fb50f;position:absolute;right:0;z-index:9}


.GuestUserBox{background-color:#f7f7f7;padding:10px;border:1px solid #ddd;border-radius:10px;display:flex;margin:0 0 20px 0}
.GuestUserBox:last-child{margin:0}
.GuestUserBox figure{width:65px;height:65px;margin:0}
.GuestUserBox figcaption{width:calc(100% - 65px);padding:0 0 0 10px}
.GuestUserBox figcaption .GuestUserHead{display:flex;position:relative}
.GuestUserBox figcaption .GuestUserHead .Approve{display:block;text-align:center;font-size:12px;color:#fff;font-weight:700;padding:8px 12px;border-radius:5px;margin:0 0 0 10px;border:none;background:#3fb50f;position:absolute;right:0;z-index:9}
.GuestUserBox figcaption .GuestUserHead aside{width:50%}
.GuestUserBox figcaption .GuestUserHead aside p{font-size:13px;margin:0 0 10px 0;display:flex}
.GuestUserBox figcaption .GuestUserHead aside p:last-child{margin:0}
.GuestUserBox figcaption .GuestUserHead aside p label{margin:0 10px 0 0;width:155px;position:relative;color:#000;font-weight:700}
.GuestUserBox figcaption .GuestUserHead aside p label:after{content:':';position:absolute;right:0;top:0}
.GuestUserBox figcaption .GuestUserHead aside p span{font-weight:600;width:calc(100% - 165px)}
.GuestUserBox figcaption .GuestUserBody{background-color:#fff;padding:15px;border:1px solid #ddd;border-radius:5px;margin:15px 0 0 0}
.GuestUserBox figcaption .GuestUserBody h6{font-weight:700;color:#000;margin:0 0 6px 0}
.GuestUserBox figcaption .GuestUserBody h6 strong{font-weight:800}
.GuestUserBox figcaption .GuestUserBody p{font-weight:700;margin:0 0 5px 0;color:#6a6a6a}
.GuestUserBox figcaption .GuestUserBody .form-group{margin:0;display:flex}
.GuestUserBox figcaption .GuestUserBody .form-group select{min-width:200px;border:1px solid #ddd;padding:10px 10px;font-weight:700;color:#000;border-radius:5px}
.GuestUserBox figcaption .GuestUserBody .form-group button{display:block;text-align:center;font-size:15px;color:#fff;font-weight:700;padding:5px 30px;border-radius:5px;margin:0 0 0 10px;border:none;background:#165885}

.GuestUserBox .card-guest{display: flex;justify-content: left;height: 100px;}
.GuestUserBox .card-guest span {
    display: flex;
    justify-content: flex-start;
    margin: 0px 15px 0px 0px;
}

.CloseCard {position:absolute;top:-13px;right:-13px;width:26px;height:26px;background-color:#fff;border:2px solid rgba(0,0,0,.5);text-align:center;color:#000;font-size:15px;border-radius:50%}
.ModalBox .modal-dialog{max-width:450px;margin:50px auto}
.ModalBox .modal-dialog .CloseModal{position:absolute;top:-13px;right:-13px;width:26px;height:26px;background-color:#fff;border:2px solid rgba(0,0,0,.5);text-align:center;color:#000;font-size:15px;border-radius:50%}
.ModalBox .modal-dialog .Decline{margin:0}
.ModalBox .modal-dialog .Decline h3{font-weight: 800;font-size:22px;margin:0 0 5px;text-align:left;color: #000;}
.ModalBox .modal-dialog .Decline p{font-size:16px;color: #545353;text-align:left;font-weight: 600;}
.ModalBox .modal-dialog .Decline h4{margin:15px 0 0 0;text-align:right}
.ModalBox .modal-dialog .Decline h4 a{text-transform:capitalize;margin:0 0 0 30px;font-size:17px;color: #1970a5;font-weight: 600;-webkit-transition:0.5s all ease-in-out;transition:0.5s all ease-in-out}
.ModalBox .modal-dialog .Decline h4 a:hover{color:#000}

.ModalBox .modal-dialog .Category{padding:10px 10px}
.ModalBox .modal-dialog .Category h3{text-align:center;font-weight: 800;margin:0 0 20px 0;border-bottom:1px solid #ddd;padding:0 0 15px;font-size:20px}
.ModalBox .modal-dialog .Category .form-group{margin:0 0 20px;position:relative}
.ModalBox .modal-dialog .Category .form-group label{display:block;font-weight: 700;color:#000;font-size:14px;}
.ModalBox .modal-dialog .Category .form-group .form-control{box-shadow:none;border:1px solid #c3c3c3;font-size:14px;color:#000;line-height:22px;padding: 10px 10px;height: 100%;font-weight: 600;}
.ModalBox .modal-dialog .Category .form-group textarea.form-control{height:auto}
.ModalBox .modal-dialog .Category .CheckinNew ul li a{display:block;text-align:center;font-size:14px;color:#000;font-weight:700;padding:15px 0;border-radius:5px}
.ModalBox .modal-dialog .Category .CheckinNew ul li p{text-align:center;font-size:14px;font-weight:700;padding:20px 0;position:relative}
.ModalBox .modal-dialog .Category .CheckinNew ul li p span{background-color:#fff;z-index:1;position:relative;padding:0 15px}
.ModalBox .modal-dialog .Category .CheckinNew ul li p:before{content:'';background-color:#d3d3d3;position:absolute;width:100%;height:1px;left:0;top:0;bottom:0;margin:auto}
.ModalBox .modal-dialog .Category .CheckinNew ul li:first-child a{background-color:#00cfd9}
.ModalBox .modal-dialog .Category .CheckinNew ul li:last-child a{background-color:#146eab;color:#fff}
.ModalBox .modal-dialog .Category .QRScanGuest h4{text-align:center;font-weight:800;font-size:18px}
.ModalBox .modal-dialog .Category .QRScanGuest figure{background-color:#ededed;padding:20px;border:1px solid #ddd;width:80%;margin:20px auto 20px}

.ModalBox .modal-dialog .Category .form-group .react-tel-input {}
.ModalBox .modal-dialog .Category .form-group .react-tel-input .form-control {
    width: 100%;
    padding: 10px 10px 10px 55px;
}


.ModalBox .modal-dialog .Category .form-group .OTPBox {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 10px;

}


.ModalBox .modal-dialog .Category .form-group  .OTPBox .form-control {
    padding: 0px;
    text-align: center;
    width: 60px;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
    color: black;
    line-height: 30px;
    border-color: gray;
}


.SuccessGuestBox{text-align:center;padding:30px}
.SuccessGuestBox span{display:block;width:160px;margin:0 auto 0}
.SuccessGuestBox h4{font-size:17px;color:#268d13;font-weight:700;line-height:32px;margin:0 0 17px 0}
.SuccessGuestBox a{background-color:#165885;padding:13px 40px;display:inline-block;border-radius:50px;color:#fff;font-weight:600;letter-spacing:.5px}





.hotel-description{}
.hotel-description h2{
    font-size: 22px;
    font-weight: 700;
}
.hotel-description aside{
    margin: 20px 0;
}
.hotel-description aside h3{
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}
.hotel-description aside p{
    font-size: 14px;
    line-height: 22px;
}
a.TitleLink {
    background-image: linear-gradient(45deg, rgb(5, 6, 6), rgb(22, 88, 133));
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
}

.ButtonOtp {
    padding: 10px;
    font-size: 12px;
}
.text-guest{color: green;}
.text-guest-unassigned{color: gray;}

.Deluxe {
    font-size: 15px;
    color: green;
    font-weight: 700;
    margin-bottom: 8px;
}

.total-bill h4 {
    font-size: 18px;
    font-weight: 700;
    color: black;
    margin: 15px 0px 5px 0px;
}
.ModalBox .modal-dialog .Decline .qr-pera{
    text-align: center;
}
.ModalBox span.qr-pera{
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    padding: 20px 0;
    position: relative;
    display: inherit;
}

.StaticBoxArea-top{
    cursor: pointer;
    background-color: rgb(233, 233, 233);
    border-radius: 0px;
    padding: 15px 15px 15px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.StaticBoxArea-top h4{
    font-size: 17px;
    color: rgb(0, 0, 0);
    font-weight: 700;
}
.StaticBoxArea-top h4 span{
    padding-right: 10px;
}
.back-btn{
    text-align: end;
    margin-top: -20px;
}
.back-btn a{
    font-size: 18px;
    display: inline-block;
    color: #225476;
    font-weight: 700;
}
.GuestUserBox figcaption .GuestUserHead aside p span.passport{
    width: 70px;
    margin: 0px 10px 0px 0px;
}
.avtar-img{
    width: 74px;
    height: 50px;
    display: inline-block;
    border: 1px solid #fff;
    box-shadow: 0px 0px 10px #b7b7b7;

    margin-top: 30px;
}
.LoginArea .LoginBox form .form-group.upload-file input{font-size: 11px;}

.UploadBox{ display: flex; }
.UploadBox .Upload{border:2px dashed #b9b9b9;padding: 37px 0;border-radius: 8px;position:relative;display: inline-block;width: 160px;height: 135px;text-align: center;background-color: #fff;}
.UploadBox .Upload i{font-size:24px}
.UploadBox .Upload span{font-size: 14px;font-weight:600;color:#000;text-transform:capitalize;display: block;}
.UploadBox .Upload input{position:absolute;top:0;left:0;width:100%;height:130px !important;cursor:pointer;opacity:0}

.UploadBox .UploadIcon {
    margin: 0 0 0 20px;
    border-radius: 5px;
    padding: 10px;
    width: 135px;
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.UploadBox .UploadIcon img{ width: 90px; }

.Icon{
    position: absolute;
    top: 55%;
    right: 2%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arrow-left{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.NewTitleBox .arrow-left a{
 background: none !important;
 color: #165885 !important;
}
.TitleBox h6 span{margin: 0px 0px 0px 10px;}


.AddressBox .autocomplete-dropdown-container {
    position: absolute;
    z-index: 99;
    background: #fff;
    width: 100%;
    box-shadow: 0px 0px 10px #b6b6b6;
}
.AddressBox .autocomplete-dropdown-container .suggestion-item{
    padding: 10px;
}


.AddressBox .autocomplete-dropdown-container .suggestion-item:hover,
.AddressBox .autocomplete-dropdown-container .suggestion-item--active{
    padding: 10px;
    background: #165885;
    color: #000;
}


.ModalBox .modal-dialog .Category .form-group .RoomBox {
    position: relative;
    /* border: 1px solid #c3c3c3; */
    display: flex;
    margin: 0 -5px;
}

.ModalBox .modal-dialog .Category .form-group .RoomBox span {
    background-color: #ddd;
    padding: 10px 20px;
    font-weight: 600;
    color: #000;
    border-right: 1px solid #c3c3c3;
}


.ModalBox .modal-dialog .Category .form-group .RoomBox .form-control {
    /* border: none; */
    margin: 0 5px;
}
.ModalBox .modal-dialog .Category .form-group .CheckBox {
    display: inline-block;
    min-width: 130px;
    min-height: auto;
}


.CategoryAdd{
    display: flex;
}

.CategoryAdd button {
    opacity: 1 !important;
    background: #d5d1d1;
    font-size: 14px;
    padding: 0 30px;
    font-weight: 600;
    color: #000;
    margin: 0 0 0 10px;
}


.ModalBox .modal-dialog .Category .form-group .___STimePicker_1gaoj_gg_{
    width: 100%;
    box-shadow: none;
    font-size: 14px;
    color: #000;
    line-height: 22px;
    padding: 10px 10px;
    height: auto;
    font-weight: 600;
    margin: 0 0 5px 0;
}

.ModalBox .modal-dialog .Category .form-group .TimePicker {
    padding: 0 0 0 0px;
}

    .ModalBox .modal-dialog .Category .form-group .TimePicker span {
    font-weight: 600;
    color: #000;
    padding: 0 13px;
}


.LoginArea .LoginBox form .form-group .react-tel-input .form-control {
    margin-left: auto;
    padding: 10px 15px 10px 20px;
}


.CommonForm .form-group  .react-tel-input .form-control{
    margin-left: 50px;
    padding: 10px 15px 10px 20px;


}


.KnowMoreBtn {margin-left: 600px; font-size: 14px; font-weight: 700; margin: 0 0 15px 0; display: inline-block;}
.KnowMoreBtn:hover {margin-left: 600px; font-size: 14px; font-weight: 700;margin: 0 0 15px 0; display: inline-block;}


.MainHeading {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}
.MainHeading h2{
    font-size: 22px;
    color: #000;
    font-weight: 500;
    font-family: roboto;
}

.FacilityBox{}
.FacilityBox ul{}
.FacilityBox ul li{
    background-color: #fff !important;
    font-weight: 500;
    padding: 16px 15px;
    color: #7C8493;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.FacilityBox ul li h4{
    font-size: 16px;
    color: #000;
}
.FacilityBox ul li aside{
    display: flex;
}
.FacilityBox ul li h4 span{
    padding-right: 56px;
    font-size: 16px;
    color: grey;
}
.FacilityBox ul li  a{
    margin-left: 10px;
}

.NoData {
    background-color: #eee;
    padding: 30px 0;
    border-radius: 5px;
}
.NoData p {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.CommonForm .form-group.HotelNumber .react-tel-input .form-control { width: calc(100% - 50px); }

.CommonForm .form-group.HotelNumber .Verify{ top: 45px; }

.veg {
    font-size: 13px;
    font-weight: 800;
    margin: 0px 10px 0px 0px;
    color: black;
}
.non-veg{
    font-size: 13px;
    font-weight: 800;
}
.FilterRight .switch .slider{border: 1px solid black;}
.FilterRight .switch .slider:before{background: #007621;}
.FilterRight .switch input:checked+.slider{background: #da1414;}

.MenuList ul li .MenuLeft figcaption.food-bav-content{
    border: 2px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    width: 300px;
    overflow: hidden;
    position: relative;
    background: white;
}
.food-bav2{
    position: absolute;
    top: 5px;
    right: 8px;
}
.food-bav2 .fa{font-size: 15px;}

.discount-package h2{
    font-size: 16px;
    color: #050558;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
}

.OrderSatus {
    background-color: #fff;
    padding: 20px;
    border: 3px dashed #ddd;
    border-radius: 10px;
    width: 500px;
    text-align: center;
    margin: auto;
}

.OrderSatus figure {
    width: 140px;
    margin: 0 auto 0px;
}

.OrderSatus figcaption {
    text-align: center;
}

.OrderSatus figcaption h3 {
    color: #009045;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 5px 0;
}

.OrderSatus figcaption h4 {
    color: #464646;
    font-size: 17px;
    font-weight: 700;
    margin: 0 0 5px 0;
}

.OrderSatus figcaption h5 {
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 5px 0;
}

.OrderSatus table {
    border: 1px solid #ddd;
    margin: 15px 0 15px 0;
}

.OrderSatus table tr th {
    background: #d9d9d9;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #acacac;
    width: 33%;
    text-align: left;
    color: #000;
    font-weight: 700;
    padding: 8px 20px;
}

.OrderSatus table tr th:last-child{ border-right: none; }

.OrderSatus table tr td {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    color: #515151;
    font-weight: 600;
    text-align: left;
    padding: 8px 20px;
}


.OrderSatus table tr:nth-child(even) {
    background: #f1f1f1;
}

.OrderSatus .OTPBox {
    display: flex;
    justify-content: center;
    margin: 0 0 10px 0;
}

.OrderSatus .OTPBox .form-control {
    width: 45px;
    height: 45px;
    text-align: center;
    margin: 0 5px;
    color: #000;
    font-weight: 700;
}

.OrderSatus p {
    text-align: center;
    font-weight: 600;
    color: #5c5c5c;
    margin-bottom: 10px;
}

.NoDataFound{
    min-height: 14vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NoDataFound h2{
    font-size: 20px;
    font-weight: 600;
    color: #000;
}


.MenuList ul li .MenuLeft figcaption{
    text-align: justify;
    width: 380px;
    padding: 10px 20px;
}
.addOccupant{
    text-align: right;
    font-size: 15px;
    font-weight: 800;
}

.manualCheckin{
    background-color: #ebeef1;
    padding: 25px;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    margin: 0 0 20px 0;
    position: relative;
}

.CommonForm .form-group .UploadBox figure{
    position: absolute;
    left: 192px;
    width: 123px !important;
    height: 123px;
    border-radius: 10px;
}



.ReviewList{}
.ReviewList ul{}
.ReviewList ul li{ margin-bottom:20px }
.ReviewList ul li{background-color:#fff;padding:20px 20px 20px 90px;border-radius:10px;position:relative}
.ReviewList ul li figure{position:absolute;top:20px;left:20px;width:60px;height:60px;border-radius:50%;overflow:hidden;margin:0}
.ReviewList ul li figcaption h4{font-weight:700;font-size:18px;margin:0 0 5px 0}
.ReviewList ul li figcaption h4 span{font-size:15px;padding:0 0 0 20px;position:relative;margin:0 0 0 10px}
.ReviewList ul li figcaption h4 span:before{content:'';position:absolute;width:6px;height:6px;background:#000;border-radius:10px;left:0;top:7px}
.ReviewList ul li figcaption h5{font-size:14px}
.ReviewList ul li figcaption h5 strong{color:#37b503;margin:0 15px 0 0;font-weight:600}
.ReviewList ul li figcaption h6{font-size:16px;margin:0 0 7px 0;font-weight:700;color:#000}
.ReviewList ul li figcaption h6 strong{font-weight:700}
.ReviewList ul li figcaption p{font-size:14px;line-height:23px;font-weight:600}

.ManualTrue{
    position: absolute;
    right: 0;
    background-color: #c70000;
    border: none;
    padding: 9px 20px;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px; 
    z-index: 99;   
} 

.ManualFalse{
    position: absolute;
    right: 0;
    background-color: #165885;
    border: none;
    padding: 8px 12px;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    font-size: 13px; 
    z-index: 99;   
} 

.createBill{
    display: flex;
flex-wrap: wrap;
justify-content: flex-start;

}

    /* 
    .___SDropdownPopper_1ogfh_gg_{
        display: block;
    } */


.approveRequestBtn{
    display: flex;
    justify-content: flex-end;
}

.Duration{
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 0 5px 0;
}


.Duration span{}

.Duration select{
    border: none;
    outline: 0;
    padding: 10px 15px;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    cursor: pointer;
}
.profile-chat {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.profile-chat figure {
    width: 50px;
    height: 50px;
    border: 1px solid gray;
    border-radius: 50%;
    margin: 0px 12px 0px 0px;
    overflow: hidden;

}
.profile-chat p {
    font-size: 14px;
    font-weight: 800;
    margin: 0px 0px 0px 10px;
}

.emp-type-radio{display: flex;}

.emp-type-radio .form-group label{margin: 0px 40px 0px 0px !important;}
    
.employe-type label{
    font-weight: 700 !important;
    font-size: 14px;}

.Radio .checkmark-new:after{
    width: 6px;
    border-radius: 0px;
    background: none;
}

.DeliveryTime{
    position: relative;
}

.DeliveryTime span{
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: transparent;
    top: 0;
    left: 0;
}


.LoaderBox {
    margin-top: 240px;
    text-align: center;
}



.NotificationsBox ul li{border:1px solid #ddd;padding:20px 20px 20px 100px;margin:0 0 20px 0;position:relative;border-radius:5px}
.NotificationsBox ul li .Icon{width:55px;height:55px;background-color:#44ADF7;position:absolute;left:20px;top:20px;font-size:30px;text-align:center;color:#fff;border-radius:50%;font-weight:600;line-height:50px}
.NotificationsBox ul li h3{font-weight:600;font-size:20px;margin:0 0 10px 0}
.NotificationsBox ul li p{font-weight:600;font-size:14px}

.NotificationsBox ul li.Unread{background-color: #ededed;}


.NotificationsArea{ padding: 50px 0;}



.BreadcumbBox{margin-bottom:20px}
.BreadcumbBox ul li{display:inline-block;margin:0 30px 0 0;position:relative}
.BreadcumbBox ul li a{font-size: 14px;color:#000;font-weight:600}
.BreadcumbBox ul li+li:before{content:'\f101';font-family:FontAwesome;position:absolute;top:2px;left:-20px;font-size:15px;line-height:20px}
.BreadcumbBox ul li.active a{color:#604083}

.room-display {
    font-size: 15px;
    color: #df9919;
    margin-top: 10px;
    margin-bottom: 0;
}

.time-input {
    width: 250px;
    height: 50px;
    font-size: 20px;
    text-align:start;
}

.NewTitleBoxForSubscription{display:flex;align-items:center;justify-content:space-between;margin:0 0 0 0}

.roomchangecheckbox{display: flex; margin-top: 10px; margin-bottom: 10px; width: 100%;  flex-direction: row; gap: 10px; align-items: center;}


/* Subscription Plans Page CSS */

.subscriptionModelheading {
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 10%;
}

.subscriptionModelpara {
    font-size: medium;
    font-weight: bold;
    /* color: #898989; */
    margin-left: 10%;
}

.subscriptionPlans {
    display: flex;
    justify-content: space-evenly;
    gap: 2%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    height: 55%;
    width: 100%;
    font-weight: 400;
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
}

.subscriptionplanBox {
    position: relative;
    /* background-color: rgba(217, 217, 217, 0.5); */
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 35px;
    padding: 50px 50px 50px 50px;
    width: 300px;
    height: 400px;
    text-align: left;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}
.subscriptionplanBox:hover {
    transform: scale(1.05);
}

.subscriptionPlanSelected {
    border: 2px solid;
    border-color: #e1b444;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.subscriptionPlanBoxheading {
    font-size: x-large;
    font-weight: bold;
    position: relative;
    margin-left: 5px;
}

.subscriptionPlanBoxSubheading {
    color: gray;
    margin-left: 5px;
    font-size: larger;
    margin-bottom: 15px;
}

.subscriptionPlanBoxDescription {
    background: linear-gradient(90deg, #062a44 0%, #0f69aa 50%);
    width: 130px;
    border-radius: 40px;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
}

.strikedtxt {
    text-decoration: line-through;
    color: #000000;
    font-weight: 700;
    font-size: larger;
    position: relative;
}

.subscriptionPricetxt {
    color: #000000;
    font-weight: 700;
    font-size: large;
    margin-top: 2px;
    position: relative;
}

.subscriptionPlanAbout {
    color: #898989;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    bottom: 96px;
}
  
.subscriptionPlanAbout strong {
    color: #e1b444; /* Change color */
    font-weight: bold; /* Adjust font weight */
}

.subscriptionChooseButtonContainer {
    width: 100%;
    align-items: center;
    bottom: 20px;
    right: auto;
    left: auto;
    position: absolute;
}

.subscriptionPlanChooseButton {
    background-color: #e1b444c4;
    color: #000000;
    border: none;
    border-radius: 18px;
    height: 55px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    width: 60%;
    transition: background-color 0.3s, transform 0.2s;
    position: relative;
}
.subscriptionPlanChooseButton:hover {
    background-color: #e1b444; /* Darker shade on hover */
    transform: scale(1.05);
}

.subscriptionPlanChooseButtonFadeOut {
    opacity: 0.5;
}

.subscriptionModelDisclaimer {
    color: #898989;
    margin-left: 130px;
    margin-right: 120px;
    margin-top: 10px;
}

.subscriptionCouponApplyContainer {
    display: flex;
    align-items: center;
    margin: 20px;
    margin-left: 10%;
    max-width: 600px;
    width: auto;
}

.subscriptionCouponInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-right: 10px;
    flex: 0.3;
    text-align: center;
}

.subscriptionCouponRemoveButton{
  padding: 10px 20px;
  border: none;
  background-color: #d9d9d9;
  color: black;
  border-radius: 20px;
  cursor: pointer;
  width: 130px;
}

.subscriptionCouponApplyButton {
    padding: 10px 20px;
    border: none;
    background-color: #e1b444;
    color: black;
    border-radius: 20px;
    cursor: pointer;
    width: 130px;
}

.subscriptionContinueBtn {
    position: absolute;
    right: 10%;
    text-decoration: none;
    background-color: #e1b444;
    padding: 10px 20px;
    border-radius: 15px;
    margin-left: 10px;
    color: #ffffff;
    width: 175px;
    text-align: center;
    border: 0;
}

.subscriptionBillingModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscriptionBillingModalContent {
    background: white;
    padding: 30px;
    border-radius: 5px;
    width: 100%;
    min-height: 290px;
    max-width: 500px;
    position: relative;
    margin-top: 10px;
}

.subscriptionBillingTitle {
    text-align: center;
    font-weight: bold;
}

.suscriptionBillingPlanDetailsBox {
    border: 1px solid #898989;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.subscriptionBillingPlanDetails {
    color: #000000;
    float: left;
    margin-left: 20px;
    font-size: large;
}

.subscriptionBillingPlanValues{
    color: #000000;
    float: right;
    margin-right: 50px;
    font-size: large;
}
  
.subscriptionBillingPlanValuesStriked{
    text-decoration: line-through;
    float:left;
    margin-left: 12%;
    font-size: large;
    color: #000000;
}

.subscriptionCouponApplied {
    margin-top: 0px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.subscriptionBillingSubtotalprice {
    color: #000000;
    font-size: large;
    margin-right: 30px;
    float: right;
}

.subscriptionBillingSubtotalBox {
    margin-top: 0px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.subscriptionBillingtotal {
    font-weight: bold;
    margin-bottom: 50px;
}

.subscriptionBillingCancelButton {
    border:none;padding: 11px 30px;text-align: center;transition:0.5s all ease-in-out;font-weight: 600;position:relative;font-size:15px;color: black;text-transform:capitalize;border-radius: 10px;outline: 0 !important;box-shadow: none;background: #d9d9d9;;display: flex; float: right; margin-left: 10px;
}

.subscriptionBillingBuyButton {
    border:none;padding: 11px 30px;text-align: center;transition:0.5s all ease-in-out;font-weight: 600;position:relative;font-size:15px;color:#fff;text-transform:capitalize;border-radius: 10px;outline: 0 !important;box-shadow: none;background: #dfa92d;display: flex; float: right;
}

.subscriptionPlanTermsandConditionlinksContainer {
    margin-left: 10%;
    display: flex;
    flex-direction: row;
}
.subscriptionPlanTermsandConditionlinks {
    color: #000000;
    padding: 10px;
    text-decoration: none;
}

/* Subscription Details Page CSS */

.subscriptionDetailsHeading {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    padding-left: 20px;
}

.subscriptionNoData {
    font-weight: bold;
    align-items: center;
    text-align: center;
    font-size: large;
    width: 100%;
    padding: 10px 10px 10px 10px;
    height: 100%;
}

.subscriptionRenewOrBuyButtonContainer{
    display: flex;
    justify-content: center;
}

.subscriptionDetailsViewMoreContainer{
    display: flex;
    float: right;
    font-weight: bold;
    padding-right: 30px;
    font-size: larger;
}

.subscriptionActiveStatus {
    border:none;
    padding: 11px 30px;
    text-align: center;
    font-weight: 600;
    position:relative;
    font-size:15px;
    color:#000000;
    text-transform:capitalize;
    border-radius: 5px;
    outline: 0 !important;
    box-shadow: none;
    background:  #00FF00;
    display: inline-block;
}

.subscriptionInctiveStatus {
    border:none;
    padding: 11px 30px;
    text-align: center;
    font-weight: 600;
    position:relative;
    font-size:15px;
    color:#000000;
    text-transform:capitalize;
    border-radius: 5px;
    outline: 0 !important;
    box-shadow: none;
    background:  #FF0000;
    display: inline-block;
}

.SubscriptionHeader {
    color: white;
    font-size: medium !important;
    align-items: center;
    font-weight: bold;
    padding: 5px;
    gap:10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    border: #FFF 2px solid;
    border-radius: 5px;
    padding: 10px
}

.SubscriptionCircle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid transparent;
}
  
.SubscriptionCircleinactive {
    border-color: #FF3737; /* red */
    background-color: #FF3737;
    animation: subscriptionFlash 2s infinite;
}
  
.SubscriptionCircleactive {
    border-color: #34C759; /* green */
    background-color: #34C759; /* green */
}

@keyframes subscriptionFlash {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
}


.SubscriptionExpiryCard {
    background-color: #165885;
    color: white;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 110%;
    width: 40%;
    float: right !important;
    margin-left: 25%;

}
  
.SubscriptionCardContent {
    display: flex;
    flex-direction: column;
    padding: 5%;
}
  
.card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}
  
.card-text {
    font-size: 16px;
    margin-bottom: 20px;
}
  
.SubscriptionExpiryCardRenewButton {
    background-color: #dfa92d;
    color: white;
    padding: 5px 5px;
    border-radius: 5px;
    cursor: pointer;
    height: 10%;
    width: 60%;
    margin-top: 10px !important;
    margin-left: 10px;
    margin-bottom: 2px !important;
    text-align: center;
    font-weight: bold;
}
  
.SubscriptionExpiryTimerCard {  
    background-color: #dfa92d;
    color: white;
    padding:10px;
    border-radius: 10px;
    text-align: center;
    width: 45%;
    height: 100%;
}
  
.card-timer-title {
    font-size: 12px;
    font-weight: bold;
}
  
.card-timer-value {
    font-size: 20px;
    font-weight: bold;
}
  
.card-timer-unit {
    font-size: 12px;
}


.InactiveRooms-alert-link {
    text-decoration: none;
    color: #ffffff; 
    box-shadow: 0 0 10px #ff0000; 
    animation: InactiveRoomglow 2s infinite; 
    background-color: #ff0000 !important;  
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: box-shadow 0.3s; /* transition effect */
    border-radius: 5px;
    margin-right: 10px;
}
  
@keyframes InactiveRoomglow {
    0% {
      box-shadow: 0 0 10px #ff0000;
    }
    50% {
      box-shadow: 0 0 50px #ff0000;
    }
    100% {
      box-shadow: 0 0 10px #ff0000;
    }
}

.subscription-warning{
    color: red !important;
    margin-right: 20px;
    font-weight: bold;
    align-items: center;
    text-align: center;
    padding-top: 10px;
}

.no-subscription-rooms {
    border: 2px red solid !important;
}

.redirect-text {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 50px;
  }
  
  .animation-dot {
    animation: blink 2s infinite;
    font-size: 30px;
  }
  
  .animation-dot:nth-child(2) {
    animation-delay: 0.5s;
  }
  
  .animation-dot:nth-child(3) {
    animation-delay: 1s;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }



.subscriptionStatusText {
    padding:10px;
    text-align:center;
}

.subscriptionStatusGif {
    display:flex;
    justify-content:center;
}

.subscriptionStatusButtons {
    display:flex;
    justify-content:space-evenly;
}

.transaction-vendor-details{
    display:flex;
    justify-content:flex-end;
    flex-direction: row;
}
.transaction-vendor-details p{
 padding-left: 10px;
}


.billWaiver{
    display:flex;
    justify-content:center;
    flex-direction: row;
}


.DeviceAuthBox {
    display: flex;
    justify-content: right;
    gap: 10%;
    width: 40%;
    align-items: right;
}
.deviceAuthenticationCheckbox{
    padding: 20px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 5%;
}

.TaxBoxPackageDiscounts {
    margin-left: 75%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.packageActionBox {
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
}

.packageFlashDealBox {
    display: flex;
    flex-direction: column;
    /* justify-content: right; */
    padding: 10px;
    padding-right: 25px;
    padding-left: 25px;
    align-items: center;
    border-radius: 10px;
    background-color: white;
    box-shadow:0 0 4px #ddd;
}

.info-tooltip {
    display: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-100%);
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    z-index: 1;
    width: 500px;
}
.info-btn {
    position: relative;
    cursor: pointer;
}

.info-btn:hover .info-tooltip {
    display: flex;
}

.deviceAuthInfo{
    /* border: black solid; */
    align-items: center;
    display: flex;
    justify-content: space-evenly;
}

.packagedisplayImagesBox {
    width: 100%;
    height: 200px;
}


.PackageList .PackageBox{background-color:#f3f3f3; border:1px solid #ddd; padding:15px; border-radius:10px; margin-top: 10px; height: 500px;}
.PackageList .PackageBox figure{background-color:#fff;padding:20px;border-radius:10px;box-shadow:0 0 4px #ddd; height:35%;}
.PackageList .PackageBox figure img{width:40%;height:100%;margin:auto;display:block}
.PackageList .PackageBox figcaption{height: 40%; padding: 10px;}
.PackageList .PackageBox figcaption h3{font-size:16px;font-weight:700;color:#000;margin:0 0 6px 0}
.PackageList .PackageBox figcaption h3 span{font-size:14px;color:#4e4e4e}
.PackageList .PackageBox figcaption h4{font-size:18px;font-weight:700;color:#609f0d;margin:0 0 5px 0}
.PackageList .PackageBox figcaption h6{margin:0 0 10px 0;font-weight:700;color:#000;font-size:14px}
.PackageList .PackageBox aside label{float:none}
.PackageList .PackageBox aside a{width:24px;display:inline-block;text-align:center}


.barGraphDiv {
    display: flex;
    float: right;
    align-items: center;
    margin-left:30%;
}

.analyticsGraphBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.renderAnalyticsGraphBox{
    padding: 15px;
    width: 1020px;
    height: 550px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.analyticsTimeFramesBox{
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.analyticsNameBox{
    font-size: x-large;
    font-weight: bold;
}


.description-page {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1, h2, h3 {
    color: #333;
    margin-bottom: 10px;
  }
  
  h1 {
    font-size: 24px;
  }
  
  h2 {
    font-size: 18px;
  }
  
  h3 {
    font-size: 16px;
  }
  
.subscriptionDescheading {
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 6%;
    margin-right: 6%;
}
.subscriptionDescSubheading {
    font-weight: bold;
    font-size: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.subscriptionDescSubheading1 {
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
}
  
  
  .subscription-benefits-section {
    margin-bottom: 20px;
    padding-top: 40px;
    font-weight: bold;
    margin-left: 6%;
    margin-right: 6%;
    text-align: justify;
  }
  
  .subscription-benefits {
    margin-bottom: 20px;
    padding-top: 20px;
    font-weight: bold;
    margin-left: 6%;
    margin-right: 4%;
    text-align: justify;
  }
  
  .subscription-benefits ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
 
  .subscription-benefits li p {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .subscription-benefits-list {
    display:flex;
    flex-direction: row;
  }

  .imageIcon{
    padding-right:15px;
    padding-top:5px;
  }
  .subs-text{
    padding-top:-20px;
    font-size: 16px;
  }

  .list-item{
    padding-top:-20px;
    font-size: 16px;
  }

  .subscription-benefits-list p {
    display:flex;
    flex-direction: row;

  }
  
  .subscription-benefits li ul {
    margin-left: 20px;
  }
  
  .what-we-provide {
    margin-bottom: 20px;
  }
  
  .what-we-provide ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .what-we-provide li {
    margin-bottom: 10px;
  }
  
  .what-we-provide li:before {
    content: "\2022";
    color: #666;
    font-size: 18px;
    margin-right: 10px;
  }
