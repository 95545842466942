@charset "utf-8";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&amp;display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif !important;
  font-size: 14px;
  color: #3a3a3a;
  font-weight: 400;
  position: relative;
}

* {
  margin: 0px;
  padding: 0px;
  }


.clearfix {
  zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.clearfix:after {
  clear: both;
}

.clr,
.clear {
  clear: both;
}

header,
footer,
aside,
article,
section,
nav,
figure {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
li,
ol {
  /* list-style-type: none; */
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none !important;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none;
}
a, a:hover, a:active, a:visited { color: white; }
img {
  border: 0;
  outline: none;
  max-width: 100%;
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
  max-width: 100%;
}
textarea:focus,
input:focus {
  outline: none;
}

table {
  width: 100%;
}
p {
  margin: 0;
  padding: 0;
}
.fltL {
  float: left;
}
.fltR {
  float: right !important;
}

.footer {
  display: flex;
  align-items: center; 
  justify-content: center; 
  margin-top: 20px; 
}
.footer p {
  margin: 0 10px;
}
.padding_none {
  padding: 0 !important;
}
.border_none {
  border: none !important;
}
.navbar-toggle {
  background-color: #fff !important;
}
.icon-bar {
  background-color: #000 !important;
}
.p_right0 {
  padding-right: 0px !important;
}
.p_left0 {
  padding-left: 0px !important;
}

.HotelArea {
  background-image: url(../assets/images/Hotel.jpg);
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.HotelArea:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 70%);
}
.HotelArea .Header {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.HotelArea .Header ol li {
  display: inline-block;
  margin: 0 15px 0 0;
}
.HotelArea .Header ol li a {
  padding: 10px 20px;
  display: block;
  border: 2px solid #fff;
  background-color: #fff;
  color: #000;
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
}
.HotelArea .Header ol li .dropdown-menu {
  min-width: 285px;
}
.HotelArea .Header ol li .dropdown-menu li {
  display: block;
  margin: 0;
}
.HotelArea .Header ol li .dropdown-menu li + li {
  border-top: 1px solid #ddd;
}
.HotelArea .Header ul {
  text-align: center;
}
.HotelArea .Header ul li {
  display: inline-block;
  margin: 0 5px;
}
.HotelArea .Header ul li a {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
}
.HotelArea .Header ul li a:hover {
  background-color: #000;
  color: #fff;
}
.HotelArea .HotelBox {
  position: relative;
  color: #fff;
  text-align: center;
  z-index: 1; 
}
.HotelArea h5 {
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #fff;
  top: 110px;
  font-size: 75px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 10px;
}
.HotelArea h5:before {
  content: "";
  display: inline-block;
  width: 60px;
  height: 5px;
  background-color: #fff;
  margin: 0 15px 0 0;
  border-radius: 13px;
}
.HotelArea .HotelBox .head {
  font-size: 27px;
  font-weight: 700;

}
.HotelArea .HotelBox form {
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  padding: 5px;
  overflow: hidden;
  margin: 0 0 30px;
  min-width: 500px;
}
.HotelArea .HotelBox form input {
  border: none;
  padding: 10px 10px 10px 15px;
  width: calc(100% - 150px);
  font-weight: 600;
}
.HotelArea .HotelBox form button {
  background-color: #000;
  border: none;
  outline: 0;
  color: #fff;
  padding: 12px 20px;
  border-radius: 50px;
  width: 150px;
  font-weight: 600;
}
.HotelArea .HotelBox p {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.4px;
  padding: 15px;
}
.HotelArea .HotelBox h4 {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.4px;
  margin-bottom: 20px;
}
.HotelArea .HotelBox {
  padding: 30px 15px 30px 15px;
}
.HotelArea .HotelBox1 {
  padding: 250px 40px 0px 40px;
}

.HotelArea .HotelBox2 {
 justify-content: center;
}

.HotelArea .Footer p {
  color: #fff;
  position: relative;
  display: block;
  margin: 20px 15px;
  font-size: 20px;
}.home1{
  
  animation-name: home1;
  animation-play-state: paused;
}

@keyframes home1 {
  0% {
    background-image: url(../assets/images/Hotel.jpg);
    background-repeat: no-repeat;
    background-size: auto contain;
    background-position: center;
  }
  100% {
    background-image: url(../assets/images/Hotel.jpg);
    background-repeat: no-repeat;
    background-size: auto contain;
    background-position: center;
  }
}


.HotelArea .Footer {
  position: relative; 
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 20px;
  margin-top: 20px;
}

.HotelArea .FooterMain p {
  color: #fff;
  position:  relative;
  display: block;
  margin: 0px 15px;
  font-size: 17px;
  text-align: center;
}

.HotelArea .FooterMain {
  position:  absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 20px 5px 20px 5px;
  
}














@media (max-width: 1298px) {
  .HotelArea .Header {
    padding: 0 10px;
  }
  .HotelArea h5 {
    font-size: 45px;
    padding: 10px 
  }

  .HotelArea h5:before {
    width: 50px;
  }
  .HotelArea .HotelBox h4 {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }


  .HotelArea .HotelBox form {
    min-width: unset; 
  }


}


@media (max-width: 868px) {

  .HotelArea h5 {.home1{
  
    animation-name: home1;
    animation-play-state: paused;
  }
  
  @keyframes home1 {
    0% {
      background-image: url(../assets/images/Hotel.jpg);
      background-repeat: no-repeat;
      background-size: auto contain;
      background-position: center;
    }
    100% {
      background-image: url(../assets/images/Hotel.jpg);
      background-repeat: no-repeat;
      background-size: auto contain;
      background-position: center;
    }
  }
  
    font-size: 38px;
    padding: 10px; 
  }

  .HotelArea h5:before {
    width: 40px;
  }
  .HotelArea .HotelBox h4 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }

}

@media (max-width: 772px) {

  .HotelArea h5 {
    font-size: 38px;
    padding: 10px;
    text-align: center;    
  }
  .HotelArea h5:before {
    width: 35px;
    display:none;
  }
  .HotelArea .Header {
    padding: 0 10px; 
  }
  
  .HotelArea .HotelBox form {
    min-width: unset;
  }
  .HotelArea .HotelBox h4 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  .HotelArea .FooterMain p {
  font-size: 16px;
  text-align: center;
}

  
}



@media (max-width: 576px) {

  .HotelArea h5 {
    font-size: 30px; 
  }
  .HotelArea h5:before {
    width: 30px;
    display:none;
  }
  .HotelArea .HotelBox h4 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.4px;
  }
.HotelArea .FooterMain p {

  font-size: 13px;
  text-align: center;
}

}

