

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.intro h1 {
  font-family: "Montserrat";
}
.section1 h1 {
  font-family: "Montserrat";
}
.section1 h2 {
  font-family: "Nunito";
}

.section5 {
  background-color: #fcda8680;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding-top: 5px;
  padding-bottom: 60px 0;
  background: white;
  white-space: nowrap;
  position: relative;
  max-width: 100%;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 10s slide infinite linear;
  width: 100%;
  white-space: nowrap;
}

.logos-slide img {
  height: 90px;
  margin: 0 20px;
}
@media screen and (min-width: 1280px) {
  .logos-slide div {
    translate: 40px;
  }
}

.intro {
  height: 100vh;
  background-image: url(../assets/images/LandingPic.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: home 20s infinite linear;
  animation-fill-mode: backwards;
}
.section3 {
  height: 100vh;
  background-image: url(../assets/images/downimg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

p,
h1,
h2,
h3 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
@media screen and (min-width: 640px) {
  .intro {
    height: 100vh;
  }
  .section3 {
    height: 100vh;
  }
  .section2 {
    height: 100vh;
  }
  .section1 {
    height: 100vh;
  }
  .section4 {
    height: 100vh;
  }
  .section5 {
    height: 120vh;
  }
}
@media screen and (min-width: 320px) {
  .main-container {
    height: 950vh;
    overflow: hidden;
  }
  .intro {
    height: 100vh;
  }
  .section3 {
    height: 110vh;
  }
  .section2 {
    height: 80vh;
  }
  .section1 {
    height: 320vh;
  }
  .section4 {
    height: 100vh;
    overflow: hidden;
  }
  .section5 {
    height: 150vh;
  }

  .section6 {
    height: 97.5vh;
  }
  .slick-slider {
    width: 90%; /* Adjust slider width for mobile */
    margin: 0 auto; /* Center the slider horizontally */
    height: 100%;
    margin-top: 90px;
  }
}

@media screen and (min-width: 768px) {
  .main-container {
    height: 650vh;
    overflow: hidden;
  }
  .intro {
    height: 100vh;
  }
  .section3 {
    height: 100vh;
  }
  .section2 {
    height: 110vh;
  }
  .section1 {
    height: 110vh;
  }
  .section4 {
    height: 100vh;
  }
  .section5 {
    height: 100vh;
  }
}
@media screen and (min-width: 1024px) {
  .main-container {
    height: 650vh;
  }
  .intro {
    height: 100vh;
  }
  .section3 {
    height: 100vh;
  }
  .section2 {
    height: 100vh;
  }
  .section1 {
    height: 100vh;
  }
  .section4 {
    height: 100vh;
  }
  .section5 {
    height: 100vh;
  }
  .section6 {
    height: 86vh;
  }
}

@media screen and (min-width: 1280px) {
  .main-container {
    height: 670vh;
  }
  .intro {
    height: 100vh;
  }
  .section3 {
    height: 100vh;
  }
  .section2 {
    height: 100vh;
  }
  .section1 {
    height: 120vh;
  }
  .section4 {
    height: 100vh;
  }
  .section5 {
    height: 100vh;
  }
  .downloadbox {
    height: 80vh;
  }
}
@media screen and (min-width: 1440px) {
  .main-container {
    height: 650vh;
  }
  .intro {
    height: 100vh;
  }
  .section3 {
    height: 100vh;
  }
  .section2 {
    height: 100vh;
  }
  .section1 {
    height: 100vh;
  }
  .section4 {
    height: 100vh;
  }
  .section5 {
    height: 100vh;
  }
  .downloadbox {
    height: 80vh;
  }
}
@keyframes home {
  0% {
    background-image: url(../assets/images/try1.jpg);
    background-repeat: no-repeat;
    background-size: auto contain;
    background-position: center;
  }
  25% {
    background-image: url(../assets/images/try2.jpg);
    background-repeat: no-repeat;
    background-size: auto contain;
    background-position: center;
  }
  50% {
    background-image: url(../assets/images/try3.jpg);
    background-repeat: no-repeat;
    background-size: auto contain;
    background-position: center;
  }
  100% {
    background-image: url(../assets/images/try4.jpg);
    background-repeat: no-repeat;
    background-size: auto contain;
    background-position: center;
  }
}

